import { AUCTION_STATUS_NOT_STARTED } from "core/consts";
import { Auction, TransitionalCare } from "core/types";
import { margin } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_24,
  FONT_WEIGHT_BOLD,
  Title,
} from "ds_legacy/materials/typography";
import React from "react";
import { useTranslations } from "translations";
import { StyledFormWrapper } from "../components";
import { FormDownload } from "./DownloadSection";

const ExplanationParagraph = ({
  auctionStatus,
}: {
  auctionStatus: Auction["status"];
}) => {
  const translations = useTranslations();

  if (auctionStatus === AUCTION_STATUS_NOT_STARTED) return null;

  return (
    <>
      <Body
        data-testid="started-banner"
        as="p"
        margin={margin(0, 0, 1)}
        maxWidth="100%"
      >
        {
          translations.patientForms.transitionalCareForm
            .explanationTransitionalCare
        }
      </Body>
      <Body as="p" margin={margin(0, 0, 3)} maxWidth="100%">
        {translations.patientForms.transitionalCareForm.numberOfProviders}
      </Body>
    </>
  );
};

export function FormWrapper({
  auctionStatus,
  children,
  transitionalCare,
}: {
  auctionStatus: Auction["status"];
  children: React.ReactNode;
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) {
  const translations = useTranslations();

  return (
    <StyledFormWrapper>
      <Title
        as="h2"
        margin={margin(0, 0, 3)}
        style={{ fontSize: FONT_SIZE_24, fontWeight: FONT_WEIGHT_BOLD }}
      >
        {translations.patientForms.transitionalCareForm.title}
      </Title>
      <ExplanationParagraph auctionStatus={auctionStatus} />
      <FormDownload transitionalCare={transitionalCare} />
      {children}
    </StyledFormWrapper>
  );
}
