import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import auth from "core/model/api/endpoints/auth";
import { QueryProgress } from "core/types";
import { usePreventUnload } from "dsl/hooks";
import { debounce } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useTranslations } from "translations";
import { validateSingleSignOn } from "../LoginPageV2/formConfig";

export function useSingleSignOn({
  handleLogin,
}: {
  handleLogin: (
    props: { token?: string },
    setProgress: Dispatch<SetStateAction<QueryProgress>>,
  ) => Promise<void>;
}) {
  const { token } = useParams();
  const isSsoRedirect = !!token;
  const translations = useTranslations();
  const [ssoUrl, setSsoUrl] = useState<string>();
  const [progressSso, setProgressSso] = useState<QueryProgress>(
    isSsoRedirect ? QUERY_PROGRESS_PENDING : QUERY_PROGRESS_NOT_STARTED,
  );
  const [progressValidateEmail, setProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const handleSsoLogin = useCallback(() => {
    if (ssoUrl) {
      setProgressSso(QUERY_PROGRESS_PENDING);
      window.location.href = ssoUrl;
    }
  }, [ssoUrl, setProgressSso]);

  const checkSsoEligibility = useCallback(
    debounce(async ({ email }: { email: string }) => {
      try {
        setProgress(QUERY_PROGRESS_PENDING);

        const isValid =
          validateSingleSignOn({ email }, { translations }) === true;
        if (!isValid) {
          throw new Error("Invalid email");
        }

        const res = await auth.oauthCheck({ email });
        if (res?.url) {
          setSsoUrl(res.url);
          setProgress(QUERY_PROGRESS_SUCCEED);
        } else {
          throw new Error("Email not eligible for SSO");
        }
      } catch (err) {
        setProgress(QUERY_PROGRESS_NOT_STARTED);
        setSsoUrl(undefined);
      }
    }, 300),
    [auth.oauthCheck],
  );

  useEffect(() => {
    if (token) {
      handleLogin({ token }, setProgressSso);
    }
  }, [handleLogin, token]);

  usePreventUnload(progressSso);

  return {
    checkSsoEligibility,
    handleSsoLogin,
    isSsoEligible: !!ssoUrl,
    isSsoRedirect,
    progressSso,
    progressValidateEmail,
    ssoUrl,
  };
}
