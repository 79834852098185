import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import { margin } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  SMALL_INPUT_WIDTH,
  TEXT_AREA_MAX_CHARACTERS,
  TextAreaWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { FormWatcher } from "react-forms-state";
import { useTranslations } from "translations";

export const AftercareCarelevel = () => {
  const translations = useTranslations();

  return (
    <>
      <CheckboxInputField
        elementName="aftercare_care_level_aftercare_required"
        label={
          translations.patientForms.transitionalCareForm.sectionC
            .afterCareRequired
        }
      />
      <CheckboxInputField
        elementName="aftercare_care_level_available"
        label={
          translations.patientForms.transitionalCareForm.sectionC.careLevel
        }
        sideMutation={(value, mutate) => {
          if (value) {
            mutate(null, "aftercare_care_level_applied");
            mutate(null, "aftercare_care_level_explanation");
            mutate(null, "aftercare_care_level_applied_date");
          }
        }}
      />
      <FormWatcher watchPath="aftercare_care_level_available">
        {({ watchedValue: careLevelAvailable }) => {
          if (careLevelAvailable) return null;
          return (
            <>
              <CheckboxInputField
                elementName="aftercare_care_level_applied"
                label={
                  translations.patientForms.transitionalCareForm.sectionC
                    .careLevelApplied
                }
                sideMutation={(value, mutate) => {
                  if (!value)
                    return mutate(null, "aftercare_care_level_applied_date");
                  return mutate(null, "aftercare_care_level_explanation");
                }}
              />
              <FormWatcher watchPath="aftercare_care_level_applied">
                {({ watchedValue: careLevelApplied }) => {
                  return careLevelApplied ? (
                    <DatePickerWithLabel
                      bold
                      label={
                        translations.patientForms.transitionalCareForm.sectionC
                          .careLevelAppliedDate
                      }
                      elementName="aftercare_care_level_applied_date"
                      wrapperSx={{ width: "100%" }}
                      inputSx={{ width: SMALL_INPUT_WIDTH }}
                      disableFuture
                    />
                  ) : (
                    <TextAreaWithLabel
                      elementName="aftercare_care_level_explanation"
                      label={
                        translations.patientForms.transitionalCareForm.sectionC
                          .textFieldLabel
                      }
                      marginOverride={margin(0)}
                      multiline
                      characterLimit={TEXT_AREA_MAX_CHARACTERS}
                    />
                  );
                }}
              </FormWatcher>
            </>
          );
        }}
      </FormWatcher>
    </>
  );
};
