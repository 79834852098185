import { PriorTreatmentSection } from "./PriorTreatment";
import { TransitionalCareSection } from "./TransitionalCare";

export function SectionTwo() {
  return (
    <>
      <PriorTreatmentSection />
      <TransitionalCareSection />
    </>
  );
}
