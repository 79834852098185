import { PDF } from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import { getStaticAsset } from "core/model/config";
import { ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS } from "core/model/utils/ontologies";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  GetOntologyType,
  TransitionalCare,
  TransitionalCareFormData,
  TransitionalCareRequest,
} from "core/types";
import { format, fromUnixTime } from "date-fns";
import { PDFDocument, PDFForm } from "pdf-lib";
import { useCallback } from "react";
import { useTranslations } from "translations";
import { DownloadSectionWrapper } from "../../components";
import { assertUnreachable, formatDate } from "../../utils";

const FORM_PDF_PATH = "uebergangspflege_2024.pdf";
const DOWNLOADED_PDF_NAME = "Übergangspflege.pdf";

const COMBINED_FIELDS = [
  "legal_representative_location_street_house_number",
  "legal_representative_location_zipcode_city",
  "patient_location_zipcode_city",
  "patient_location_street_house_number",
  "clinic_location_street_number",
  "clinic_location_zipcode_city",
] as const;

const combineTextFields = ({
  combined,
  first,
  form,
  formData,
  last,
}: {
  combined: (typeof COMBINED_FIELDS)[number];
  first: keyof TransitionalCareFormData;
  form: PDFForm;
  formData: TransitionalCareFormData;
  last: keyof TransitionalCareFormData;
}) => {
  const firstValue = formData[first];
  const secondValue = formData[last];
  const text = [firstValue, secondValue].truthy().join(", ");

  if (!text.length) return;

  form.getTextField(combined).setText(text);
};

const formatDateTime = (timestamp: number, time: number, locale: Locale) =>
  `${formatDate(timestamp, locale)} ${format(fromUnixTime(time), "HH:mm", {
    locale,
  })}`;

const setRequest =
  ({
    form,
    getOntology,
    locale,
  }: {
    form: PDFForm;
    getOntology: GetOntologyType;
    locale: Locale;
  }) =>
  (request: TransitionalCareRequest, i: number) => {
    const fieldIndex = i + 1;
    const careproviderField = form.getTextField(
      `careprovider_details_${fieldIndex}`,
    );

    const {
      careprovider_address_address,
      careprovider_address_city,
      careprovider_address_zipcode,
      careprovider_name,
      request_sent_date,
      request_sent_time,
      request_status,
    } = request;

    careproviderField.setText(
      `${careprovider_name}, ${careprovider_address_address}, ${careprovider_address_city} ${careprovider_address_zipcode}`,
    );

    const dateField = form.getTextField(`request_sent_date_${fieldIndex}`);
    const date = formatDateTime(request_sent_date!, request_sent_time!, locale);
    dateField.setText(date);

    const statusField = form.getTextField(`request_status_${fieldIndex}`);
    const status = getOntology({
      type: ONTOLOGY_TRANSITIONAL_CARE_REQUEST_STATUS,
      key: request_status,
    });
    statusField.setText(status);
  };

const useHandleFillPDF = () => {
  const { currentLocale: locale } = useDateLocale();
  const getOntology = useGetOntology();

  const handleFillPDF = useCallback(
    async (transitionalCare: Readonly<TransitionalCare>) => {
      try {
        const formUrl = getStaticAsset(FORM_PDF_PATH);
        const formPdfBytes = await fetch(formUrl).then((res) =>
          res.arrayBuffer(),
        );
        const pdfDoc = await PDFDocument.load(formPdfBytes);
        const form = pdfDoc.getForm();
        const formData = JSON.parse(
          transitionalCare.form_data?.decrypted ?? "{}",
        ) as TransitionalCareFormData;

        Object.keys(formData).forEach((key) => {
          try {
            const field = key as keyof TransitionalCareFormData;
            const fieldValue = formData[field];

            switch (field) {
              // DATE FIELDS
              case "handover_to_insurance_date":
              case "insurance_form_sent_date":
              case "transitional_care_start_date":
              case "transitional_care_start_search_date":
              case "transitional_care_patient_confirmation_date":
              case "aftercare_start_date":
              case "aftercare_application_date":
              case "patient_date_of_birth":
              case "prior_treatment_end_date":
              case "aftercare_care_level_applied_date":
              case "prior_treatment_start_date":
              case "transitional_care_end_date": {
                if (!fieldValue) break;

                const date = formatDate(fieldValue as number, locale);
                form.getTextField(field).setText(date);

                break;
              }
              // COMBINED TEXT FIELDS
              case "legal_representative_location_street":
                break;
              case "legal_representative_location_street_number": {
                combineTextFields({
                  first: "legal_representative_location_street",
                  last: "legal_representative_location_street_number",
                  combined: "legal_representative_location_street_house_number",
                  form,
                  formData,
                });
                break;
              }
              case "legal_representative_location_zipcode":
                break;
              case "legal_representative_location_city": {
                combineTextFields({
                  first: "legal_representative_location_zipcode",
                  last: "legal_representative_location_city",
                  combined: "legal_representative_location_zipcode_city",
                  form,
                  formData,
                });
                break;
              }
              case "patient_location_street":
                break;
              case "patient_location_house_number": {
                combineTextFields({
                  first: "patient_location_street",
                  last: "patient_location_house_number",
                  combined: "patient_location_street_house_number",
                  form,
                  formData,
                });
                break;
              }
              case "patient_location_zipcode":
              case "patient_location_city": {
                combineTextFields({
                  first: "patient_location_zipcode",
                  last: "patient_location_city",
                  combined: "patient_location_zipcode_city",
                  form,
                  formData,
                });
                break;
              }
              case "clinic_location_street":
                break;
              case "clinic_location_street_number": {
                combineTextFields({
                  first: "clinic_location_street",
                  last: "clinic_location_street_number",
                  combined: "clinic_location_street_number",
                  form,
                  formData,
                });
                break;
              }
              case "clinic_location_zipcode":
                break;
              case "clinic_location_city": {
                combineTextFields({
                  first: "clinic_location_zipcode",
                  last: "clinic_location_city",
                  combined: "clinic_location_zipcode_city",
                  form,
                  formData,
                });
                break;
              }
              // CHECKBOX RADIOS
              case "aftercare_care_level_applied":
              case "transitional_care_suitable_providers_more_than_twenty":
              case "aftercare_care_level_available":
              case "internet_portal_used":
              case "aftercare_mobile":
              case "aftercare_medical_rehab":
              case "aftercare_application_submitted":
              case "aftercare_shortterm_care":
              case "legal_representative_consent_provided":
              case "transitional_care_patient_confirmation":
              case "aftercare_care_level_aftercare_required": {
                const pdfField = form.getRadioGroup(field);

                pdfField.clear();

                pdfField.select(fieldValue ? "yes" : "no");

                break;
              }
              // RADIO GROUP
              case "care_necessity": {
                const pdfField = form.getRadioGroup(field);

                pdfField.clear();

                if (fieldValue) {
                  pdfField.select(fieldValue.toString());
                }

                break;
              }
              // REQUEST LIST
              case "request_list": {
                if (!Array.isArray(fieldValue)) {
                  break;
                }
                fieldValue.forEach(setRequest({ form, locale, getOntology }));
                break;
              }
              // NUMBER FIELDS
              case "transitional_care_suitable_providers_count": {
                if (fieldValue == null) break;

                form.getTextField(field).setText(fieldValue.toString());
                break;
              }
              // TEXT FIELDS
              case "aftercare_application_explanation":
              case "aftercare_care_level_explanation":
              case "clinic_ik_number":
              case "clinic_name":
              case "legal_representative_first_name":
              case "legal_representative_last_name":
              case "patient_first_name":
              case "patient_insurance_number":
              case "patient_last_name":
              case "prior_treatment_hospital_id":
              case "social_worker_email":
              case "social_worker_fax":
              case "social_worker_first_name":
              case "social_worker_last_name":
              case "social_worker_telephone":
              case "transitional_care_department_code":
              case "transitional_care_description":
              case "transitional_care_location_id":
              case "transitional_care_patient_confirmation_explanation": {
                if (fieldValue == null) break;

                if (typeof fieldValue !== "string") {
                  console.warn("Unhandled field type", {
                    field,
                    value: fieldValue,
                  });
                  break;
                }

                form.getTextField(field).setText(fieldValue);
                break;
              }
              default: {
                // ensure all form data keys are handled in the pdf
                assertUnreachable(field);
              }
            }
          } catch (err) {
            console.error(`Error filling PDF field: ${key}`, err);
          }
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: PDF });
        const url = URL.createObjectURL(blob);

        window.open(url, "_blank");
      } catch (err) {
        console.error("Error saving PDF", err);
      }
    },
    [locale, getOntology],
  );

  return handleFillPDF;
};

export const FormDownload = ({
  transitionalCare,
}: {
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) => {
  const translations = useTranslations();
  const handleFillPDF = useHandleFillPDF();

  if (!transitionalCare) return null;

  return (
    <DownloadSectionWrapper
      title={
        translations.patientForms.transitionalCareForm.downloadSection.title
      }
      body={
        translations.patientForms.transitionalCareForm.downloadSection.bodyText
      }
      lastUpdated={transitionalCare.updated_at!}
      onClick={() => handleFillPDF(transitionalCare)}
      pdfName={DOWNLOADED_PDF_NAME}
    />
  );
};
