import { CARE_NECESSITIES } from "core/consts";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import { RADIO_WHITE_BACKGROUND } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  GAP,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
  Section,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionF = () => {
  const translations = useTranslations();

  const radioOptions: RadioOptionV2[] = [
    {
      id: CARE_NECESSITIES.AFTER_CARE_ENSURED_DURING,
      label: translations.patientForms.transitionalCareForm.sectionF.bulletOne,
      value: CARE_NECESSITIES.AFTER_CARE_ENSURED_DURING,
    },
    {
      id: CARE_NECESSITIES.AFTER_CARE_COULD_BE_ENSURED,
      label: translations.patientForms.transitionalCareForm.sectionF.bulletTwo,
      value: CARE_NECESSITIES.AFTER_CARE_COULD_BE_ENSURED,
    },
    {
      id: CARE_NECESSITIES.FULL_STOP_OUTSIDE_ENTITLEMENT,
      label:
        translations.patientForms.transitionalCareForm.sectionF.bulletThree,
      subForm: (
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.sectionF
              .dateFieldLabel
          }
          flatModel
          elementName="aftercare_start_date"
          wrapperSx={{ width: "100%", margin: margin(0, 0, 1, -0.5) }}
          inputSx={{
            width: SMALL_INPUT_WIDTH,
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
        />
      ),
      value: CARE_NECESSITIES.FULL_STOP_OUTSIDE_ENTITLEMENT,
    },
    {
      id: CARE_NECESSITIES.NO_FURTHER_NEED,
      label: translations.patientForms.transitionalCareForm.sectionF.bulletFour,
      value: CARE_NECESSITIES.NO_FURTHER_NEED,
    },
  ];

  return (
    <Section
      title={translations.patientForms.transitionalCareForm.sectionF.title}
    >
      <HorizontalLayout
        padding={padding(GAP, 2, 0, 2)}
        margin={margin(0, 0, 0, -1)}
      >
        <RadioGroupV2
          label=""
          formControlLabelSx={{ paddingBottom: sizing(1) }}
          radioGroupSx={{ rowGap: sizing(1) }}
          elementName="care_necessity"
          options={radioOptions}
          sideMutation={(_, mutate) => mutate(null, "aftercare_start_date")}
          radioSx={RADIO_WHITE_BACKGROUND}
        />
      </HorizontalLayout>
    </Section>
  );
};
