import { TRACK_EVENTS } from "core/consts";
import { getQueryVariable } from "core/model/utils/urls";
import { Auction } from "core/types";
import React, { useCallback, useEffect, useRef } from "react";
import { useTracking } from "react-tracking";

export type TrackManualActions = ({
  auction,
  sessionId,
}: {
  auction: Auction;
  sessionId: string;
}) => void;

export default function useTrackManualActions({
  activeStep,
  shouldTrack,
}: {
  activeStep: string;
  shouldTrack: boolean;
}) {
  const clicksRef = useRef(0);
  const keystrokesRef = useRef(0);
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (keystrokesRef.current > 0) keystrokesRef.current = 0;
    if (clicksRef.current > 0) clicksRef.current = 0;
  }, [activeStep]);

  useEffect(() => {
    if (!shouldTrack) return;

    function handleKeystroke() {
      keystrokesRef.current += 1;
    }

    function handleClick() {
      clicksRef.current += 1;
    }

    document.addEventListener("keydown", handleKeystroke);
    document.addEventListener("pointerdown", handleClick);

    return () => {
      document.removeEventListener("keydown", handleKeystroke);
      document.removeEventListener("pointerdown", handleClick);
    };
  }, [shouldTrack]);

  const trackManualActions: TrackManualActions = useCallback(
    ({ auction, sessionId }: { auction: Auction; sessionId: string }) => {
      if (!shouldTrack) return;
      trackEvent({
        name: TRACK_EVENTS.ASSESSMENT_MANUAL_ACTIONS,
        step: activeStep,
        is_last: !!auction.assessment_completed,
        assessment_session_id: sessionId,
        kis_import: !!auction.patient.kis_import,
        search_type: auction.search_type,
        assessment_variant: auction.assessment_variant,
        clicks: clicksRef.current,
        keystrokes: keystrokesRef.current,
        manual_actions: clicksRef.current + keystrokesRef.current,
      });
    },
    [shouldTrack, activeStep],
  );

  return trackManualActions;
}

export function withTrackManualActions(
  WrappedComponent: React.FunctionComponent<any>,
) {
  return function WrappedWithTrackManualActions(props: any) {
    const sessionId = getQueryVariable(location.search, "session_id");
    const trackManualActions = useTrackManualActions({
      shouldTrack: !!sessionId,
      activeStep: props?.slug,
    });
    return (
      <WrappedComponent {...props} trackManualActions={trackManualActions} />
    );
  };
}
