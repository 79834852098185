import RootPage from "containers/RootPage";
import {
  PATIENT_TAB_FILES,
  PATIENT_TAB_MAP,
  PATIENT_TAB_PROFILE,
  PATIENT_TAB_REHAB_FORMS,
  PATIENT_TAB_SEARCH,
  PATIENT_TAB_TIMELINE,
  PATIENT_TAB_TRANSITIONAL_CARE,
  REDIRECT_PARAM,
} from "core/consts";
import {
  hasAccessToOnPremDebug,
  hasAccessToPatientDashboard,
  hasAccessToUserManagement,
} from "core/model/accounts";
import { isFilesActivatedAtAuctionLevel } from "core/model/auctions";
import {
  activateSingerAssessment,
  isNewTheme,
  isProd,
} from "core/model/utils/featureFlags";
import { useSenderAccountContext } from "dsl/atoms/Contexts";
import { UnsupportedBrowser } from "dsl/atoms/ErrorPages";
import RedirectRoot from "dsl/atoms/RedirectRoot";
import { SearchCandidatesProvider } from "dsl/atoms/SearchCandidates";
import ThrowError from "dsl/atoms/ThrowError";
import lazy from "dsl/atoms/retry";
import { CareseekerAuthPage } from "dsl/ecosystems/CareseekerAuthPage";
import { areNewRehabFormsActive } from "dsl/ecosystems/PatientForms/RehabForms/utils";
import { getTransitionalCareAction } from "dsl/ecosystems/PatientForms/TransitionalCare";
import PatientHome, {
  PatientHomeMenu,
  usePatientHomeContext,
} from "dsl/ecosystems/PatientHome/connect";
import TailwindDemoPage from "dsl/ecosystems/TailwindDemoPage";
import { AssessmentPatientMenu as AssessmentMenu } from "dsl/organisms/PatientMenu";
import { DebugPage, RequireCondition } from "entries/receiver/Router/helpers";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { useIsLoggedOnClinicApp } from "reduxentities/selectors";
import { useTokenStatusCheck } from "reduxentities/selectors/hooks";
export const defaultRedirectPath = "/app/clinic/dashboard/todo/active";
export const userManagementRedirectPath = "/app/clinic/dashboard/users";
export const onpremDebugRedirectPath = "/app/clinic/dashboard/onpremsetup/test";

const EmptyPage = () => <div style={{ flex: "1" }} />;

const CheckTokenStatus = ({ children }: { children?: ReactNode | null }) => {
  useTokenStatusCheck();
  return <>{children ? children : <Outlet />}</>;
};

function CheckRoles({
  children,
  defaultRoute,
  setDefaultRoute,
}: {
  children?: ReactNode;
  defaultRoute: string;
  setDefaultRoute: Dispatch<SetStateAction<string>>;
}) {
  const navigate = useNavigate();
  const { account, activeRoles } = useSenderAccountContext();

  useEffect(() => {
    if (
      !hasAccessToPatientDashboard(account, activeRoles) &&
      hasAccessToUserManagement(account, activeRoles)
    ) {
      if (defaultRoute !== userManagementRedirectPath) {
        setDefaultRoute(userManagementRedirectPath);
        navigate(userManagementRedirectPath);
      }
    } else if (
      !hasAccessToPatientDashboard(account, activeRoles) &&
      hasAccessToOnPremDebug(account, activeRoles)
    ) {
      if (defaultRoute !== onpremDebugRedirectPath) {
        setDefaultRoute(onpremDebugRedirectPath);
        navigate(onpremDebugRedirectPath);
      }
    }
  }, [defaultRoute, account]);

  return <>{children ? children : <Outlet />}</>;
}

const CareseekerAuthCheck = ({ children }: { children?: ReactNode | null }) => {
  const logged = useIsLoggedOnClinicApp();

  return logged === "ok" ? (
    <>{children ? children : <Outlet />}</>
  ) : (
    <Navigate
      to={`/auth?${REDIRECT_PARAM}=${encodeURIComponent(
        `${location.pathname}${location.search}`,
      )}`}
    />
  );
};
const ForgotPasswordPage = lazy(
  () => import("dsl/ecosystems/ForgotPasswordPage"),
);
const OnPremiseDebugPage = lazy(
  () => import("dsl/ecosystems/OnPremiseDebugPage"),
);

const SenderDashboardPage = lazy(
  () => import("dsl/ecosystems/SenderDashboard"),
);
const ReportPage = lazy(
  () => import("dsl/organisms/Careseeker/ClinicReportPage"),
);
const UserManagementPage = lazy(
  () => import("dsl/organisms/Careseeker/UserManagementPage"),
);
const BarthelSummaryPage = lazy(
  () =>
    import("dsl/ecosystems/IndependenceScaleSummaryPage/BarthelSummaryPage"),
);
const EarlyRehabSummaryPage = lazy(
  () =>
    import("dsl/ecosystems/IndependenceScaleSummaryPage/EarlyRehabSummaryPage"),
);
const SingerSummaryPage = lazy(
  () => import("dsl/ecosystems/IndependenceScaleSummaryPage/SingerSummaryPage"),
);
const DiagnosticPage = lazy(() => import("dsl/organisms/DiagnosticPage"));
const LogoutPage = lazy(() => import("dsl/ecosystems/LogoutPage"));
const PrintableSenderDashboardPage = lazy(
  () => import("dsl/ecosystems/SenderDashboard/PrintableSenderDashboard"),
);
const PatientCreatePage = lazy(() => import("dsl/organisms/PatientCreatePage"));
const PasswordResetPage = lazy(() => import("dsl/organisms/PasswordResetPage"));
const PasswordResetPageV2 = lazy(
  () => import("dsl/organisms/PasswordResetPageV2"),
);
const PasswordChangePage = lazy(
  () => import("dsl/organisms/PasswordChangePage"),
);
const PasswordChangePageV2 = lazy(
  () => import("dsl/ecosystems/PasswordChangePageV2"),
);
const ClinicDashboard = lazy(() => import("dsl/ecosystems/ClinicDashboard"));
const DischargeConfirmation = lazy(
  () => import("dsl/ecosystems/DischargeConfirmation"),
);
const PatientFiles = lazy(() => import("dsl/ecosystems/PatientFiles"));
const PatientProfile = lazy(() => import("dsl/ecosystems/PatientProfile"));
const PatientSearch = lazy(() => import("dsl/ecosystems/PatientSearchMerge"));
const PatientAssessment = lazy(
  () => import("dsl/ecosystems/PatientAssessment/connect"),
);
const PatientTimeline = lazy(() => import("dsl/ecosystems/AuctionTimeline"));
const AuctionResponse = lazy(
  () => import("dsl/ecosystems/AuctionResponse/connect"),
);
const PatientMap = lazy(() => import("dsl/ecosystems/PatientMap"));
const PatientTransitionalCare = lazy(
  () => import("dsl/ecosystems/PatientForms/TransitionalCare"),
);
const PrintableSearchMergePanel = lazy(
  () => import("dsl/ecosystems/PatientSearchMerge/print"),
);
const PatientRehabFormsHome = lazy(
  () => import("dsl/ecosystems/PatientForms/RehabForms"),
);
const RehabFormRenderer = lazy(
  () => import("dsl/ecosystems/PatientForms/RehabForms/FormRenderer"),
);

function RoutePatientAuction() {
  const { auction, patient } = usePatientHomeContext();

  const activateRehabFormsFeature = areNewRehabFormsActive(auction);

  return (
    <Routes>
      <Route element={<SearchCandidatesProvider />}>
        {/* Assessment */}
        <Route
          path="assessing/*"
          element={<AssessmentMenu auction={auction} patient={patient} />}
        >
          <Route index element={<PatientAssessment auction={auction} />} />
          <Route
            path=":assessmentSlug"
            element={<PatientAssessment auction={auction} />}
          />
        </Route>
        {/* Barthel */}
        <Route
          path="barthel-index"
          element={
            <BarthelSummaryPage patient={patient} auctionId={auction.id} />
          }
        />
        <Route
          path="singer-index"
          element={
            <RequireCondition
              condition={activateSingerAssessment()}
              redirectTo={PATIENT_TAB_SEARCH}
            />
          }
        >
          <Route
            index
            element={
              <SingerSummaryPage patient={patient} auctionId={auction.id} />
            }
          />
        </Route>
        <Route
          path="early-rehab-index"
          element={
            <EarlyRehabSummaryPage patient={patient} auctionId={auction.id} />
          }
        />
        {/* Request */}
        <Route path="request/:requestId" element={<AuctionResponse />} />
        {/* Patient Home */}
        <Route path="*" element={<PatientHomeMenu auction={auction} />}>
          <Route
            path={PATIENT_TAB_SEARCH}
            element={<PatientSearch auction={auction} patient={patient} />}
          />
          <Route
            path={PATIENT_TAB_PROFILE}
            element={<PatientProfile auction={auction} />}
          />
          <Route
            element={
              <RequireCondition
                condition={isFilesActivatedAtAuctionLevel(auction)}
                redirectTo={PATIENT_TAB_SEARCH}
              />
            }
          >
            <Route
              path={PATIENT_TAB_FILES}
              element={<PatientFiles auction={auction} patient={patient} />}
            />
          </Route>
          <Route
            path={PATIENT_TAB_TIMELINE}
            element={<PatientTimeline auction={auction} patient={patient} />}
          />
          <Route
            element={
              <RequireCondition
                condition={!!getTransitionalCareAction(auction)}
                redirectTo={PATIENT_TAB_SEARCH}
              />
            }
          >
            <Route
              path={PATIENT_TAB_TRANSITIONAL_CARE}
              element={
                <PatientTransitionalCare auction={auction} patient={patient} />
              }
            />
          </Route>
          <Route
            path={PATIENT_TAB_MAP}
            element={<PatientMap auction={auction} patient={patient} />}
          />
          <Route
            element={
              <RequireCondition
                condition={activateRehabFormsFeature}
                redirectTo={PATIENT_TAB_SEARCH}
              />
            }
          >
            <Route
              path={PATIENT_TAB_REHAB_FORMS}
              element={
                <PatientRehabFormsHome auction={auction} patient={patient} />
              }
            />
            <Route
              path={`${PATIENT_TAB_REHAB_FORMS}/:form`}
              element={
                <RehabFormRenderer auction={auction} patient={patient} />
              }
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to={PATIENT_TAB_SEARCH} replace />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default function CareseekerRoutes() {
  const [defaultRoute, setDefaultRoute] = useState(defaultRedirectPath);

  return (
    <Routes>
      <Route index element={<RedirectRoot to={defaultRoute} />} />
      <Route path="/" element={<RootPage appName="clinic" />}>
        <Route path="diagnostic" element={<DiagnosticPage />} />
        <Route path="info" element={<DebugPage />} />
        <Route
          path="playground"
          element={
            <RequireCondition condition={!isProd}>
              <TailwindDemoPage />
            </RequireCondition>
          }
        />
        <Route path="throwerror" element={<ThrowError />} />
        <Route path="home" element={<EmptyPage />} />
        <Route path="auth" element={<CareseekerAuthPage />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="auth/:token" element={<CareseekerAuthPage />} />
        <Route path="logout" element={<LogoutPage />} />
        <Route path="unsupported_browser" element={<UnsupportedBrowser />} />
        <Route path="app/*">
          <Route path="clinic/*" element={<CareseekerAuthCheck />}>
            <Route element={<CheckTokenStatus />}>
              <Route path="dashboard/*">
                <Route element={<ClinicDashboard />}>
                  <Route
                    element={
                      <CheckRoles
                        setDefaultRoute={setDefaultRoute}
                        defaultRoute={defaultRoute}
                      />
                    }
                  >
                    <Route path="todo">
                      <Route path=":tab" element={<SenderDashboardPage />} />
                      <Route
                        path="*"
                        element={<Navigate to={defaultRoute} replace />}
                      />
                    </Route>
                    <Route
                      path="debug-on-premise"
                      element={
                        <Navigate to={onpremDebugRedirectPath} replace />
                      }
                    />
                    <Route
                      path="onpremsetup/:page"
                      element={<OnPremiseDebugPage />}
                    />
                    <Route path="users" element={<UserManagementPage />} />
                    <Route path="report" element={<ReportPage />} />
                    <Route
                      path="report_test"
                      element={<ReportPage isTestQuickSightDashboard />}
                    />
                    <Route path="patient/new" element={<PatientCreatePage />} />
                    <Route path="patient/:patientId" element={<PatientHome />}>
                      <Route
                        path={"auction/:auctionId/*"}
                        element={<RoutePatientAuction />}
                      />
                    </Route>
                    <Route
                      path="my_password_change"
                      element={
                        isNewTheme() ? (
                          <PasswordChangePageV2 />
                        ) : (
                          <PasswordChangePage />
                        )
                      }
                    />
                    <Route
                      path="*"
                      element={<Navigate to={defaultRoute} replace />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path="printableDashboard/:careseekerId"
                element={<PrintableSenderDashboardPage />}
              />
              <Route
                path="discharge/:patientId/:auctionId"
                element={<DischargeConfirmation />}
              />
              <Route
                path="printable-search-panel/:patientId/:auctionId"
                element={<PrintableSearchMergePanel />}
              />
              <Route
                path="*"
                element={<Navigate to={defaultRoute} replace />}
              />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Route>
        <Route
          path="reset/:token"
          element={
            isNewTheme() ? (
              <PasswordResetPageV2 authRoute="/app/auth" />
            ) : (
              <PasswordResetPage authRoute="/app/auth" />
            )
          }
        />
        <Route path="*" element={<RedirectRoot to={defaultRoute} />} />
      </Route>
    </Routes>
  );
}
