import { ClinicSection } from "./Clinic";
import { LegalRepresentationSection } from "./LegalRepresentation";
import { PatientContactSection } from "./PatientContact";
import { SocialWorkerSection } from "./SocialWorker";

export function SectionOne() {
  return (
    <>
      <ClinicSection />
      <SocialWorkerSection />
      <PatientContactSection />
      <LegalRepresentationSection />
    </>
  );
}
