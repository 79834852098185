export default function TailwindDemoPage() {
  return (
    <div className="flex w-full max-w-lg flex-col divide-divider-500 place-self-center bg-primary-50 p-8">
      <h1 className="text-md text-primary-500">Welcome</h1>
      <p className="text-sm text-primary-900 tablet:text-md desktop:text-large">
        to Tailwind CSS
      </p>
    </div>
  );
}
