import {
  useUpdateReceiverSessionKey,
  useUpdateReceiversSessionKeys,
} from "apollo/hooks/mutations";
import { useIsSealdOnly } from "containers/SealdOnlyProvider";
import { useEnvContext } from "context/EnvContext";
import {
  CAREPROVIDER_ACTIVE,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  REQUEST_ACTION_ACCESS_TO_DATA,
  SEARCH_ACTION_SHARE_PATIENT_DATA,
  TRACK_EVENTS,
} from "core/consts";
import { useSafeState } from "core/hooks";
import { importPrivateKey } from "core/model/crypto";
import {
  decryptSessionKey,
  generateExtraSessionKey,
} from "core/model/crypto/cryptoService";
import { getPatientSessionKey } from "core/model/patients/encryption";
import { findRequestAction } from "core/model/requests";
import { computeSealdDisplayId, getSealdSDKInstance } from "core/seald";
import { addUsersToSession } from "core/seald/sessions";
import {
  Account,
  AnyObject,
  Auction,
  AuctionRequest,
  Patient,
  QueryProgress,
  SessionKey,
} from "core/types";
import {
  EncryptionContext,
  EncryptionKeyContext,
  usePatientEncryption,
  useSenderAccountContext,
} from "dsl/atoms/Contexts";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import useSearchAction from "dsl/hooks/useSearchAction";
import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { useTracking } from "react-tracking";
import { useLoggedInAccount, usePrivateKey } from "reduxentities/selectors";
import { useTranslations } from "translations";
import Translations from "translations/types";
import {
  UseSessionKeyHook,
  fetchKeysdecryptKey,
  getAccounts,
  useGenerateSessionKeysDecryptKey,
} from "./shared";

export function usePatientSessionKeys(): UseSessionKeyHook {
  const { isSealdOnly } = useIsSealdOnly();
  const { account, careseeker, privateKey, socialWorkers } =
    useSenderAccountContext();
  const accounts = getAccounts(socialWorkers, account);

  return useGenerateSessionKeysDecryptKey({
    account,
    accounts,
    privateKey,
    trackingEvent: TRACK_EVENTS.PATIENT_SESSION_KEY_UNAVAILABLE,
    trackingContext: {
      careseeker_id: careseeker?.id,
    },
    flag: false, // No need to decrypt the key anymore as encryption is not available at create step anymore
    skip: isSealdOnly(),
  });
}

async function getExtraSessionKey(
  privateKey: string,
  sessionKey: SessionKey,
  context: { id: number; public_key: CryptoKey },
) {
  try {
    const importedPrivateKey = await importPrivateKey(privateKey);
    if (!importedPrivateKey) throw new Error("no private key");

    const decrypted = await decryptSessionKey(
      sessionKey.session_key,
      importedPrivateKey,
      sessionKey?.algorithm,
    );

    if (!decrypted) throw new Error("no decrypted session key");

    return generateExtraSessionKey(decrypted, context);
  } catch (err) {
    console.error("error getting extra cryptokey - ", err);
  }
}

export function getAccountsToGiveAccess({
  auctionRequest,
  patient,
}: {
  auctionRequest: AuctionRequest;
  patient: Patient | null | undefined;
}) {
  const patientSessionKeys = patient?.session_keys || [];

  const accountsToAdd = (auctionRequest?.accounts || []).filter(
    (account: Account) => {
      if (!account.public_key) return false;
      if (auctionRequest.careprovider?.status !== CAREPROVIDER_ACTIVE)
        return false;

      const withRoles = account.roles?.careprovider_roles?.some(
        (role) => role.careprovider?.id === auctionRequest.careprovider?.id,
      );

      const withAccess =
        patientSessionKeys.find(
          ({ account_id }: { account_id: number }) => account_id === account.id,
        ) != null;

      return withRoles && !withAccess;
    },
    [],
  );

  return accountsToAdd;
}

function getSharePatientWithProgress(
  sharePatientPromise: () => Promise<any>,
  progress: QueryProgress,
  setProgress: (progress: QueryProgress) => void,
  toast: ReturnType<typeof useToast>,
  translations: Translations,
): () => Promise<string | void> {
  return () => {
    if (
      progress == QUERY_PROGRESS_NOT_STARTED ||
      progress == QUERY_PROGRESS_FAILED
    ) {
      setProgress(QUERY_PROGRESS_PENDING);
      return new Promise<string>((resolve, reject) =>
        sharePatientPromise()
          .then(() => {
            setProgress(QUERY_PROGRESS_SUCCEED);
            resolve("");
          })
          .catch((e: any) => {
            console.error("Share patient not available:", e);
            setProgress(QUERY_PROGRESS_FAILED);
            toast({
              message: translations.auctionRequest.tryAgain,
              color: "danger",
            });
            reject(e);
          }),
      );
    }
    return Promise.resolve("ok");
  };
}

export function useSharePatientWithReceiver({
  auction,
  auctionRequest,
  trackingContext,
}: {
  auction: Auction | null | undefined;
  auctionRequest: AuctionRequest | null | undefined;
  trackingContext?: AnyObject;
}): [() => Promise<string | void>, QueryProgress] {
  const patient = auction?.patient;
  const [progress, setProgress] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const { account: loggedAccount, privateKey } = useSenderAccountContext();
  const { trackEvent } = useTracking();
  const { isSealdOnly } = useIsSealdOnly();
  const [mutate] = useUpdateReceiverSessionKey(
    patient?.id ?? -1,
    auctionRequest?.careprovider?.id ?? -1,
  );
  const envContext = useEnvContext();
  const toast = useToast();
  const translations = useTranslations();
  const [sharePatientAction] = useSearchAction({
    actionType: SEARCH_ACTION_SHARE_PATIENT_DATA,
  });

  const sharePatientPromise = useCallback(() => {
    if (!patient || !auctionRequest) return Promise.reject("Null objects");
    const mySessionKey = getPatientSessionKey(loggedAccount, patient);

    if (!mySessionKey) return Promise.reject("You don't have a session key");

    const accountsToAdd = getAccountsToGiveAccess({
      patient,
      auctionRequest,
    });

    const context = {
      auction_request_id: auctionRequest?.id,
      patient_id: patient?.id,
      careprovider_id: auctionRequest?.careprovider?.id,
    };

    if (!(accountsToAdd.length > 0)) {
      trackEvent({
        name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVER,
        ...context,
        status: "error",
        error: "no_accounts",
      });

      return Promise.reject("No accounts to generate keys for");
    }

    trackEvent({
      name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVER,
      ...context,
      status: "success",
      accounts: JSON.stringify(accountsToAdd.map(({ id }: { id: any }) => id)),
    });

    if (!privateKey) return Promise.reject("no private key");

    const keysToAdd = Promise.all(
      accountsToAdd.map(async (account: any) => {
        return {
          ...(await getExtraSessionKey(privateKey, mySessionKey, {
            id: account.id,
            public_key: account.public_key,
          })),
          patient_id: patient.id,
        };
      }),
    );

    return keysToAdd.then(mutate);
  }, [mutate, patient, auctionRequest, trackingContext]);

  const sharePatientWithSealdPromise = useCallback(async () => {
    const sealdSDKInstance = await getSealdSDKInstance();
    if (!sealdSDKInstance) return;

    const careproviderId = auctionRequest?.careprovider?.id;
    if (!patient || !careproviderId) return Promise.reject("Null objects");
    const patientSealdSessionId = patient.seald_encryption_context?.seald_id;

    if (!patientSealdSessionId) {
      // if the patient was created before Seald was released - eventually should return a Promise.reject
      return;
    }

    trackEvent({
      name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVER_WITH_SEALD,
      auction_request_id: auctionRequest.id,
      patient_id: patient?.id,
      careprovider_id: auctionRequest.careprovider?.id,
      status: "success",
    });

    const careproviderDisplayId = computeSealdDisplayId({
      id: careproviderId,
      type: "careprovider",
      envContext,
    });

    return addUsersToSession({
      sessionId: patientSealdSessionId,
      entityUsersDisplayIds: [careproviderDisplayId],
      createAccessCallback: (entityIds) =>
        sharePatientAction({
          auction,
          context: {
            careprovider_ids: entityIds.map(([, id]) => id),
          },
        }),
    });
  }, [mutate, patient, auctionRequest, trackingContext]);
  const combinedSharePatientPromise = useCallback(
    async () =>
      Promise.all([sharePatientPromise(), sharePatientWithSealdPromise()]),
    [sharePatientPromise, sharePatientWithSealdPromise],
  );
  const sharePatientWithProgress = getSharePatientWithProgress(
    isSealdOnly({
      oldSession: patient?.session_key_context?.has_session_keys,
      newSealdSession: patient?.seald_encryption_context?.seald_id,
    })
      ? sharePatientWithSealdPromise
      : combinedSharePatientPromise,
    progress,
    setProgress,
    toast,
    translations,
  );

  return [sharePatientWithProgress, progress];
}

export function useSharePatientWithReceivers({
  auction,
  auctionRequests,
  trackingContext,
}: {
  auction: Auction;
  auctionRequests: Array<AuctionRequest>;
  trackingContext?: AnyObject;
}): [() => Promise<string | void>, QueryProgress] {
  const patient = auction.patient;
  const [progress, setProgress] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const { account: loggedAccount, privateKey } = useSenderAccountContext();
  const { trackEvent } = useTracking();
  const { isSealdOnly } = useIsSealdOnly();
  const [mutate] = useUpdateReceiversSessionKeys();
  const envContext = useEnvContext();

  const toast = useToast();

  const translations = useTranslations();
  const [sharePatientAction] = useSearchAction({
    actionType: SEARCH_ACTION_SHARE_PATIENT_DATA,
  });

  const sharePatientPromise = useCallback(() => {
    if (!patient || !auctionRequests) return Promise.reject("Null objects");
    const mySessionKey = getPatientSessionKey(loggedAccount, patient);

    if (!mySessionKey) return Promise.reject("You don't have a session key");

    // we need to ensure patient data is only share once per patient/careprovider,
    // especially when we send several requests to same provider for different solutions
    const filteredRequests = auctionRequests.filter(
      ({ careprovider_id }, i) =>
        auctionRequests.findIndex(
          (request) => careprovider_id === request.careprovider_id,
        ) === i,
    );

    const accountsToAdd = filteredRequests.map((auctionRequest) => ({
      careproviderId: auctionRequest.careprovider?.id,
      accounts: getAccountsToGiveAccess({
        patient,
        auctionRequest,
      }),
    }));

    if (!(accountsToAdd.length > 0)) {
      trackEvent({
        name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVERS,
        status: "error",
        error: "no_accounts",
      });

      return Promise.reject("No accounts to generate keys for");
    }

    trackEvent({
      name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVERS,
      status: "success",
      accounts: JSON.stringify(
        accountsToAdd
          .map(({ accounts }) => accounts)
          .flat()
          .map(({ id }: { id: any }) => id),
      ),
    });

    if (!privateKey) return Promise.reject("no private key");

    return Promise.all(
      accountsToAdd.map(async ({ accounts, careproviderId }) => {
        const keysToAdd = Promise.all(
          accounts.map(async (account: any) => {
            return {
              ...(await getExtraSessionKey(privateKey, mySessionKey, {
                id: account.id,
                public_key: account.public_key,
              })),
              patient_id: patient.id,
            };
          }),
        );

        return keysToAdd.then((keys) =>
          mutate({ variables: { id: careproviderId, input: keys } }),
        );
      }),
    );
  }, [mutate, patient, auctionRequests.length, trackingContext]);

  const sharePatientWithSealdPromise = useCallback(async () => {
    const sealdSDKInstance = await getSealdSDKInstance();
    if (!sealdSDKInstance) return;

    if (!patient || !auctionRequests) return Promise.reject("Null objects");
    const patientSealdSessionId = patient.seald_encryption_context?.seald_id;

    if (!patientSealdSessionId) {
      // if the patient was created before Seald was released - eventually should return a Promise.reject
      return;
    }

    // we need to ensure patient data is only share once per patient/careprovider,
    // especially when we send several requests to same provider for different solutions
    const filteredRequests = auctionRequests.filter(
      ({ careprovider_id }, i) =>
        auctionRequests.findIndex(
          (request) => careprovider_id === request.careprovider_id,
        ) === i,
    );

    const careprovidersToAdd = filteredRequests
      .map((auctionRequest) =>
        auctionRequest?.careprovider_id
          ? computeSealdDisplayId({
              id: auctionRequest.careprovider_id,
              type: "careprovider",
              envContext,
            })
          : undefined,
      )
      .truthy();

    if (!careprovidersToAdd.length) {
      trackEvent({
        name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVERS_WITH_SEALD,
        status: "error",
        error: "no_careproviders",
      });

      return Promise.reject("No careproviders to generate sessions for");
    }

    trackEvent({
      name: TRACK_EVENTS.SHARE_PATIENT_WITH_RECEIVERS_WITH_SEALD,
      status: "success",
      careproviders: JSON.stringify(careprovidersToAdd),
    });

    return addUsersToSession({
      sessionId: patientSealdSessionId,
      entityUsersDisplayIds: careprovidersToAdd,
      createAccessCallback: (entityIds) =>
        sharePatientAction({
          auction,
          context: {
            careprovider_ids: entityIds.map(([, id]) => id),
          },
        }),
    });
  }, [mutate, patient, auctionRequests.length, trackingContext]);

  const combinedSharePatientPromise = useCallback(
    async () =>
      Promise.all([sharePatientPromise(), sharePatientWithSealdPromise()]),
    [sharePatientPromise, sharePatientWithSealdPromise],
  );
  const sharePatientWithProgress = getSharePatientWithProgress(
    isSealdOnly({
      oldSession: patient?.session_key_context?.has_session_keys,
      newSealdSession: patient?.seald_encryption_context?.seald_id,
    })
      ? sharePatientWithSealdPromise
      : combinedSharePatientPromise,
    progress,
    setProgress,
    toast,
    translations,
  );

  return [sharePatientWithProgress, progress];
}

export function PatientEncryptionProvider({
  children,
  decryptedSessionKey,
  patient,
}: {
  children: ReactNode;
  decryptedSessionKey?: SessionKey | null;
  patient: Patient | null | undefined;
}) {
  const account = useLoggedInAccount();
  const privateKey = usePrivateKey();

  if (!account || !privateKey)
    return (
      <EncryptionKeyContext.Provider value={null}>
        <EncryptionContext.Provider
          value={{
            account,
            hasEncryption: false,
          }}
        >
          {children}
        </EncryptionContext.Provider>
      </EncryptionKeyContext.Provider>
    );

  if (decryptedSessionKey != null) {
    return (
      <EncryptionKeyContext.Provider
        value={decryptedSessionKey.session_key as unknown as CryptoKey}
      >
        <EncryptionContext.Provider
          value={{
            account,
            hasEncryption: true,
          }}
        >
          {children}
        </EncryptionContext.Provider>
      </EncryptionKeyContext.Provider>
    );
  }

  if (patient?.seald_encryption_context != null) {
    return (
      <EncryptionKeyContext.Provider value={null}>
        <EncryptionContext.Provider
          value={{
            account,
            hasEncryption: true,
          }}
        >
          {children}
        </EncryptionContext.Provider>
      </EncryptionKeyContext.Provider>
    );
  }

  const sessionKey = patient?.session_keys?.find(
    (s) => s.account_id == account.id,
  );
  if (!sessionKey || !patient)
    return (
      <EncryptionKeyContext.Provider value={null}>
        <EncryptionContext.Provider
          value={{
            account,
            hasEncryption: false,
          }}
        >
          {children}
        </EncryptionContext.Provider>
      </EncryptionKeyContext.Provider>
    );

  return (
    <DecryptPatientSessionKey
      resourceId={patient.id}
      context={{ patient_id: patient.id }}
      sessionKey={sessionKey}
      privateKey={privateKey}
      account={account}
    >
      {children}
    </DecryptPatientSessionKey>
  );
}

function DecryptPatientSessionKey({
  account,
  children,
  context,
  privateKey,
  resourceId,
  sessionKey,
}: {
  account: Account;
  children: React.ReactNode;
  context: AnyObject;
  privateKey: string;
  resourceId: number;
  sessionKey: SessionKey;
}) {
  const [accessDenied, setAccessDenied] = useState(false);
  const [decryptedSessionKey, setDecryptedSessionKey] =
    useState<CryptoKey | null>(null);

  const { trackEvent } = useTracking();

  useLayoutEffect(() => {
    fetchKeysdecryptKey({
      context,
      privateKey,
      sessionKey,
      setAccessDenied,
      setDecryptedSessionKey,
      trackEvent,
    });
  }, [resourceId, sessionKey]);

  if (!decryptedSessionKey && !accessDenied) return null;

  return (
    <EncryptionKeyContext.Provider value={decryptedSessionKey}>
      <EncryptionContext.Provider
        value={{
          accessDenied,
          hasEncryption: true,
          account,
        }}
      >
        {children}
      </EncryptionContext.Provider>
    </EncryptionKeyContext.Provider>
  );
}

export const useCanShareData = ({
  auctionRequest,
}: {
  auctionRequest: AuctionRequest;
}): boolean => {
  const { isSealdOnly } = useIsSealdOnly();
  const { encryptionAvailable } = usePatientEncryption();

  if (
    isSealdOnly({
      oldSession: auctionRequest?.session_key_context?.has_session_keys,
      newSealdSession: auctionRequest?.seald_encryption_context?.seald_id,
    })
  ) {
    if (!encryptionAvailable) return false;

    return !!findRequestAction(auctionRequest, REQUEST_ACTION_ACCESS_TO_DATA);
  }

  const availableAccounts = getAccountsToGiveAccess({
    patient: auctionRequest.auction?.patient,
    auctionRequest,
  });

  if (
    !encryptionAvailable ||
    !findRequestAction(auctionRequest, REQUEST_ACTION_ACCESS_TO_DATA) ||
    auctionRequest.auction?.share_patient_data_automatically
  ) {
    return false;
  }

  return !!availableAccounts?.length;
};
