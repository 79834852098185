import {
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const ClinicSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.contactInformation
          .titleHospital
      }
    >
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .hospitalName
          }
          large
          elementName="clinic_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .hospitalIkNumber
          }
          large
          elementName="clinic_ik_number"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .hospitalStreet
          }
          large
          elementName="clinic_location_street"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .hospitalStreetNumber
          }
          elementName="clinic_location_street_number"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .hospitalZipCode
          }
          elementName="clinic_location_zipcode"
        />
      </SectionRow>
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .hospitalCity
          }
          large
          elementName="clinic_location_city"
        />
      </SectionRow>
    </Section>
  );
};
