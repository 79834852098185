import {
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SocialWorkerSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.contactInformation
          .titleSocialWorker
      }
    >
      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .socialWorkerName
          }
          large
          elementName="social_worker_last_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .socialWorkerFirstName
          }
          large
          elementName="social_worker_first_name"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .socialWorkerPhone
          }
          elementName="social_worker_telephone"
          textInputType="tel"
        />
      </SectionRow>

      <SectionRow>
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .socialWorkerFax
          }
          elementName="social_worker_fax"
          textInputType="tel"
        />
        <TextInputWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.contactInformation
              .socialWorkerEmail
          }
          large
          elementName="social_worker_email"
        />
      </SectionRow>
    </Section>
  );
};
