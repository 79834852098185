import { useNotifySocialWorker } from "apollo/hooks/mutations";
import { TRACK_EVENTS } from "core/consts";
import { getName } from "core/model/accounts";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Account, Auction, Patient } from "core/types";
import { ChangeDialog } from "dsl/ecosystems/PatientAssessment/ReceiverAssigneeChange";
import { useCallback, useState } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

export function differentAssignee(
  newPatient: Patient | undefined,
  oldPatient: Patient | undefined,
  account: Account | null,
  initialAuction?: Auction,
): [boolean, number | null | undefined] {
  const newAssignee = newPatient?.social_worker;
  let oldAssignee;
  if (initialAuction?.assessment_completed) {
    oldAssignee = initialAuction?.patient.assignee;
  } else {
    oldAssignee = oldPatient?.social_worker;
  }

  const assigneeChanged =
    oldAssignee &&
    newAssignee &&
    oldAssignee?.id !== newAssignee?.id &&
    // Don't notify yourself
    newAssignee.id !== account?.id;

  if (assigneeChanged) return [true, newAssignee?.id];

  return [false, null];
}

export default function ChangeSocialWorker({
  assigned,
  nextNotification,
  patientId,
}: {
  assigned: Account | null;
  nextNotification?: () => void;
  patientId: number;
}) {
  const [notifySocialWorker, queryProgress] = useNotifySocialWorker({
    patientId,
  });
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { trackEvent } = useTracking();

  const skip = useCallback(() => {
    trackEvent({
      name: TRACK_EVENTS.NOTIFY_SOCIAL_WORKER,
      action: "skip",
    });

    if (nextNotification) nextNotification();
  }, [nextNotification]);

  const [text, setText] = useState("");

  const confirm = useCallback(() => {
    trackEvent({
      name: TRACK_EVENTS.NOTIFY_SOCIAL_WORKER,
      action: "notify",
    });

    notifySocialWorker({ message: text }).finally(() => {
      if (nextNotification) nextNotification();
    });
  }, [notifySocialWorker, text]);

  return (
    <ChangeDialog
      title={translations.patient.notifySocialWorker.title({
        assignedName: getName(assigned, getOntology, {
          withSalutation: true,
          withAcademicTitle: true,
        }),
      })}
      text={text}
      setText={setText}
      skip={skip}
      queryProgress={queryProgress}
      confirm={confirm}
      translations={translations}
    />
  );
}
