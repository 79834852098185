import {
  CORONA_TESTED,
  OVERHEIGHT,
  PREDICAMENT_STATE_YES,
  REHABILITATION_PAYMENT_OPTION,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  VALIDATION_MISSING,
} from "core/consts";
import {
  WEIGHT_RANGES,
  formatPickUpTime,
  getAgeIntervalFromRangeStr,
  getAges,
  hasMobileSolution,
} from "core/model/patients";
import {
  convertBirthDateIn,
  convertBirthDateOut,
  getAgeFromDate,
  validateBirthDate,
} from "core/model/utils/dates";
import {
  convertAddressToLocation,
  validateZipcode,
} from "core/model/utils/location";
import {
  EncryptedField,
  MedicalProductType,
  PatientWhitelist,
  SearchType,
} from "core/types";
import { format } from "date-fns";
import { requiredHouseNumber } from "dsl/molecules/AddressInputGroup/utils";
import { Iterable } from "immutable";
import {
  ValidationFunction,
  algoliaReactSelectDef,
  convertEncryptedFieldIn,
  convertEncryptedFieldOut,
  convertOutAssertPath,
  required,
  validateHeight,
  validateWeight,
  valueDef,
  valueDefActivableInput,
  whitelistActivableInputModelDefinition,
  whitelistEncryptedDef,
  whitelistGroupDef,
  whitelistValueDef,
} from "react-forms-state";
import { AssessmentStepTypeName } from "../AssessmentStep";

export const requiredBySearchType = (
  requiredInTypes: number[],
  searchType?: SearchType | null | undefined,
) => {
  if (requiredInTypes.includes(searchType || -1)) return true;
  return false;
};

export const covidValidation: ValidationFunction = (
  value: any,
  { translations },
) => {
  if (value.corona_state === CORONA_TESTED && !value.corona_test_results) {
    return {
      corona_test_results: translations.general.required,
    };
  }
  return true;
};

export const coronaWhitelist = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => {
  return {
    ...whitelistValueDef("corona_state", {
      whitelist,
    }),
    corona: {
      ...whitelistValueDef("test_result", {
        out_name: "corona_test_results",
        whitelist,
      }),
      ...whitelistValueDef("description", {
        out_name: "corona_description",
        whitelist,
      }),
      ...whitelistValueDef("vaccinated", {
        out_name: "vaccinated",
        validate(value) {
          // make text field required, when checkbox is selected
          if (typeof value === "string") {
            return value.length > 0;
          }

          return true;
        },
        whitelist,
      }),
    },
  };
};

export const productsWhitelistDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => {
  return whitelistValueDef("products", {
    whitelist,
    validate: (values: any) => {
      const products = (
        Iterable.isIterable(values) ? values?.toJS() : values
      ) as MedicalProductType[] | null;

      return !!products?.truthy()?.length;
    },
    convertOut: (
      values: any | null | undefined,
    ): MedicalProductType[] | null => {
      const products = (
        Iterable.isIterable(values) ? values?.toJS() : values
      ) as MedicalProductType[] | null;

      const definedProducts = products?.truthy();

      if (!definedProducts?.length) return null;

      return definedProducts.map(
        ({
          description,
          id,
          location,
          name,
          product_group,
          subgroup,
          type,
        }: MedicalProductType) => ({
          id,
          name,
          description,
          product_group,
          location,
          subgroup,
          type,
        }),
      );
    },
  });
};

type PayersInsuranceObj = {
  label?: string | null;
  value?: number | string | null;
};

type PayersInfoValue =
  | Iterable<"label" | "value", number | string | null>
  | PayersInsuranceObj
  | string
  | null;

const getPayersInsurance = (
  value: PayersInfoValue,
): PayersInsuranceObj | null => {
  if (value == null || typeof value !== "object") return null;

  const parsedValue =
    "toJS" in value ? (value.toJS() as PayersInsuranceObj) : value;
  return parsedValue;
};

export const validatePayersInsurance = (
  value: PayersInfoValue,
  props: any,
  globalValue?: Iterable<any, any> | null,
) => {
  const payersInsurance = getPayersInsurance(value);
  if (
    globalValue?.get("selected_payment_method") ===
    REHABILITATION_PAYMENT_OPTION.INSURANCE
  ) {
    if (!payersInsurance?.label || !payersInsurance?.value) return false;
  }

  return true;
};

export const convertOutPayersInfoAssessment = (
  value: PayersInfoValue,
  globalValue: Iterable<any, any> | null,
  assertedPaymentOption: number,
) => {
  if (!globalValue) {
    return null;
  }

  const selectedPaymentOption = globalValue.get("selected_payment_method");
  if (selectedPaymentOption !== assertedPaymentOption) {
    return null;
  }

  return convertOutPayers(value);
};

export const convertOutPayers = (value: PayersInfoValue) => {
  const payersInsurance = getPayersInsurance(value);
  if (!payersInsurance) return value;

  const payersInsuranceId = payersInsurance?.value
    ? parseInt(payersInsurance.value as string)
    : 0;

  if (payersInsuranceId <= 0 || isNaN(payersInsuranceId)) {
    return null;
  }

  const result = {
    name: payersInsurance?.label || "",
    id: payersInsuranceId,
  };

  return result;
};

const removeLocation = (props: any) => {
  const initialSolutions = props?.initialAuction?.solutions;
  const currentSolutions = props?.formInputValue?.solutions;

  let initialAuctionHasMobile, currentPatientHasMobile;

  if (initialSolutions && currentSolutions) {
    initialAuctionHasMobile = hasMobileSolution(initialSolutions);
    currentPatientHasMobile = hasMobileSolution(currentSolutions);
  }

  return initialAuctionHasMobile !== currentPatientHasMobile;
};

const defaultLocationValue = (
  location: Location | undefined,
  props: any,
  elementName: string | undefined,
) => {
  const locationRemoved = removeLocation(props);

  const showCareseekerAddress =
    props?.formInputValue?.search_type === SEARCH_TYPE_TRANSPORT &&
    elementName === "search_location";

  if (!location || locationRemoved) {
    if (showCareseekerAddress)
      return convertAddressToLocation(props.careseeker.address);
    return {};
  }
  return null;
};

export function validateAddress(
  address:
    | {
        city: string;
        coordinates: { latitude: number; longitude: number; zipcode: string };
        country?: string;
        encrypted_house_number?: EncryptedField;
        floor?: number;
        house_number?: string;
        radius: number;
        street: string;
        zipcode: string;
      }
    | undefined,
): boolean {
  if (
    !address?.street ||
    !address.zipcode ||
    !address.city ||
    !(address.house_number || address.encrypted_house_number)
  ) {
    return false;
  }
  return true;
}

export const locationDef = (
  elementName: string,
  options: {
    fieldRequired?: boolean;
    validate?: ValidationFunction;
    whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
  },
) => ({
  ...whitelistValueDef(elementName, {
    whitelist: options.whitelist,
    convertIn: (location, props) => {
      const defaultLocation = defaultLocationValue(
        location,
        props,
        elementName,
      );
      if (defaultLocation) return defaultLocation;

      const houseNumberMap = location.getIn(["encrypted_house_number"], null);
      const houseNumberEncrypted = convertEncryptedFieldIn(houseNumberMap);

      return {
        coordinates: {
          latitude: location.getIn(["latitude"], null),
          longitude: location.getIn(["longitude"], null),
        },
        street: location.getIn(["street"], null),
        encrypted_house_number: { decrypted: houseNumberEncrypted },
        radius: location.getIn(["radius_in_meter"], null),
        zipcode: location.getIn(["zipcode"], null),
        city: location.getIn(["city"], null),
        house_number: location.getIn(["house_number"], null),
        floor: location.getIn(["floor"], null),
      };
    },
    convertOut: (location) => {
      if (!location) return {};
      return {
        latitude: location.getIn(["coordinates", "latitude"], null),
        longitude: location.getIn(["coordinates", "longitude"], null),
        radius_in_meter: location.getIn(["radius"], null),
        zipcode: location.getIn(["zipcode"], null),
        encrypted_house_number: convertEncryptedFieldOut(
          location.getIn(["encrypted_house_number", "decrypted"], null),
        ),
        city: location.getIn(["city"], null),
        street: location.getIn(["street"], null),
        floor: location.getIn(["floor"], null),
      };
    },
    validate: (value, props, globalValue) => {
      if (options?.validate) {
        return options.validate(value, props, globalValue);
      }

      if (!options.fieldRequired) {
        return true;
      }

      const zipcodeValid = validateZipcode(options.fieldRequired)(value);
      if (zipcodeValid !== true) {
        return zipcodeValid;
      }

      if (
        requiredHouseNumber(props?.formInputValue?.auction?.solutions) &&
        !value.getIn(["radius"], 0)
      ) {
        return VALIDATION_MISSING;
      }

      return true;
    },
  }),
});

export const carelevelDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) =>
  whitelistGroupDef("carelevel", {
    whitelist,
    out: "carelevel",
    convertIn: (value: AnyObject | null | undefined) =>
      !value
        ? {
            level: null,
            has_applied: null,
            higher_level: null,
            expedited_request: null,
          }
        : value,
    ...whitelistValueDef("level", {
      whitelist,
      fieldRequired: true,
    }),
    ...whitelistValueDef("has_applied", { whitelist }),
    ...whitelistValueDef("higher_level", { whitelist }),
    ...whitelistValueDef("expedited_request", { whitelist }),
  });

export const payersDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => ({
  payers: {
    ...whitelistValueDef("payers", {
      whitelist,
    }),
    ...whitelistValueDef("selected_payment_method", {
      whitelist,
      validate: (value, props) =>
        required({ errorString: props.translations.actions.missing })(
          value,
          props,
        ),
    }),
    ...algoliaReactSelectDef("insurance", {
      whitelist,
      out_name: "payers_insurance",
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.INSURANCE,
        ),
      validate: validatePayersInsurance,
    }),
    ...whitelistValueDef("civil_servants_aid", {
      whitelist,
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.CIVIL_SERVANTS_AID,
        ),
    }),
    ...whitelistValueDef("public_accident_insurance", {
      whitelist,
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.PUBLIC_ACCIDENT_INSURANCE,
        ),
    }),
    ...algoliaReactSelectDef("public_pension", {
      whitelist,
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.PUBLIC_PENSION,
        ),
    }),
    ...whitelistValueDef("social_service_department", {
      whitelist,
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.SOCIAL_SERVICE_DEPARTMENT,
        ),
    }),
    ...whitelistValueDef("self_payer", {
      whitelist,
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.SELF_PAYER,
        ),
    }),
    ...whitelistValueDef("other_payer", {
      whitelist,
      convertOut: (value, _props, globalValue) =>
        convertOutPayersInfoAssessment(
          value,
          globalValue,
          REHABILITATION_PAYMENT_OPTION.OTHER,
        ),
    }),
    ...whitelistValueDef("further_information", { whitelist }),
    ...whitelistValueDef("patient_has_health_insurance_state", {
      whitelist,
    }),
    ...whitelistValueDef("supplementary_insurance", { whitelist }),
    ...whitelistValueDef("supplementary_insurance_state", { whitelist }),
    ...whitelistValueDef("supplementary_payment", { whitelist }),
    ...whitelistValueDef("supplementary_payment_state", { whitelist }),
    ...whitelistValueDef("free_from_supplementary_payment_state", {
      whitelist,
    }),
  },
});

export const communicationDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => {
  return {
    ...whitelistValueDef("decisions_and_language", {
      whitelist,
    }),
    ...whitelistValueDef("patient_cannot_communicate_in_german", {
      whitelist,
    }),
    ...whitelistValueDef("patient_language", {
      whitelist,
      convertIn: (input: string | null) => input || "",
      convertOut: convertOutAssertPath(
        "patient_cannot_communicate_in_german",
        "",
      ),
    }),
    ...whitelistValueDef("has_guardian", { whitelist }),
    ...whitelistValueDef("guardian_description", {
      whitelist,
      convertIn: (input: string | null) => input || "",
      convertOut: convertOutAssertPath("has_guardian", ""),
    }),
    ...whitelistEncryptedDef("guardian_contact_information", {
      whitelist,
    }),
    ...whitelistValueDef("guardian_requested", { whitelist }),
    ...whitelistValueDef("relatives_available", { whitelist }),
    ...whitelistEncryptedDef("relatives_description", {
      whitelist,
    }),

    ...whitelistValueDef("patient_is_contact", { whitelist }),
    ...whitelistEncryptedDef("patient_is_contact_description", {
      whitelist,
    }),
  };
};

export const ageDef = (
  withEncryption: boolean,
  whitelist: Record<string, boolean> | undefined,
): AnyObject => {
  const age_interval = whitelistValueDef("age_interval", {
    whitelist,
    convertIn: (input: any) => {
      const age_interval = Iterable.isIterable(input) ? input.toJS() : input;
      if (age_interval) {
        if (!age_interval.min) {
          return `< ${age_interval.max}`;
        }
        if (!age_interval.max) {
          return `> ${age_interval.min}`;
        }
        return `${age_interval.min} - ${age_interval.max - 1}`;
      }

      return null;
    },
    convertOut: (output: any, props: any, formOutValue: any) => {
      if (withEncryption) {
        const age = getAgeFromDate(formOutValue.getIn(["birth_date"]));
        const ageRanges = getAges(props.formInputValue.search_type);

        const range = ageRanges.find(({ max, min }) => {
          if (min == null) return age <= (max || 9999);
          if (max == null) return age >= (min || 0);
          return age >= min && age <= max;
        });
        return range;
      }
      const formValue = Iterable.isIterable(output) ? output.toJS() : output;
      const ageIntervalValue = formValue.value || formValue;
      const patient = props.patient;

      return typeof ageIntervalValue === "string"
        ? getAgeIntervalFromRangeStr(ageIntervalValue, patient?.search_type)
        : ageIntervalValue;
    },
    validate: (value: any, props: any) =>
      withEncryption
        ? true
        : required({ errorString: props.translations.actions.missing })(
            value,
            props,
          ),
  });

  if (withEncryption)
    return {
      ...age_interval,
      ...whitelistEncryptedDef("birth_date", {
        whitelist,
        fieldRequired: true,
        convertOut: convertBirthDateOut,
        convertIn: convertBirthDateIn,
        validate: validateBirthDate,
      }),
    };

  return {
    ...age_interval,
  };
};

export const infectionsAndGermsDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => ({
  ...whitelistValueDef("infection_and_germs_state", {
    whitelist,
    defaultValue: null,
    validate: (value: number | null, { translations }) => {
      return required()(value) === true && value !== 0
        ? true
        : {
            customMessage:
              translations.patient.medicalDiagnosis.currentInfectionsError,
          };
    },
  }),
  ...valueDefActivableInput("diagnosis.infection_and_germs"),
  ...whitelistGroupDef("infection_and_germs", {
    whitelist,
    convertOut: (value: ToType, _: AnyObject, allValues: ToType) => {
      if (allValues?.getIn !== undefined) {
        const stateValue = allValues.getIn(["infection_and_germs_state"]);
        const remarksValue = allValues.getIn(["remarks"]);
        if (stateValue !== PREDICAMENT_STATE_YES) {
          return remarksValue
            ? {
                remarks: remarksValue,
                mrsa: null,
                clostridien: null,
                three_mrgn: null,
                four_mrgn: null,
                vre: null,
                other: null,
                requires_isolation: null,
              }
            : null;
        }
      }
      return value;
    },
    ...whitelistValueDef("mrsa", { whitelist }),
    ...whitelistValueDef("clostridien", { whitelist }),
    ...whitelistValueDef("three_mrgn", { whitelist }),
    ...whitelistValueDef("four_mrgn", { whitelist }),
    ...whitelistValueDef("vre", { whitelist }),
    ...whitelistValueDef("other", {
      whitelist,
      validate(value, props) {
        if (typeof value === "string" && !value) {
          return required({ errorString: props.translations.actions.missing })(
            value,
            props,
          );
        }

        return true;
      },
    }),
    ...whitelistValueDef("remarks", { whitelist }),
    ...whitelistValueDef("requires_isolation", { whitelist }),
  }),
});

export const getWeightModel = ({
  searchType,
  whitelist,
  withEncryption,
}: {
  searchType?: SearchType | null | undefined;
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
  withEncryption: boolean;
}) => {
  const required = requiredBySearchType([SEARCH_TYPE_TRANSPORT], searchType);
  const weight_interval = whitelistValueDef("weight_interval", {
    whitelist,
    fieldRequired: required && !withEncryption,
    convertOut: (output: any, props: any, formOutValue: any) => {
      const weight = formOutValue.getIn(["weight"]);
      if (!weight) return null;

      if (withEncryption) {
        const range = WEIGHT_RANGES.find(
          (queriedRange) =>
            queriedRange?.min <= Number(weight) &&
            queriedRange?.max >= Number(weight),
        );
        return range;
      }
      return null;
    },
  });

  if (withEncryption)
    return {
      ...weight_interval,
      ...whitelistEncryptedDef("weight", {
        whitelist,
        fieldRequired: required && withEncryption,
        validate: validateWeight,
      }),
    };

  return {
    ...weight_interval,
  };
};

export const getHeightModel = ({
  searchType,
  whitelist,
  withEncryption,
}: {
  searchType?: SearchType | null | undefined;
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
  withEncryption: boolean;
}) => {
  const required = requiredBySearchType([SEARCH_TYPE_TRANSPORT], searchType);
  const overHeight = whitelistValueDef("height_interval", {
    whitelist,
    fieldRequired: required && !withEncryption,
    convertOut: (output: any, props: any, formOutValue: any) => {
      const height = formOutValue.getIn(["height"]);

      if (withEncryption) {
        if (height && Number(height) > OVERHEIGHT)
          return { min: OVERHEIGHT, max: null };
        if (height) {
          return { min: 0, max: null };
        }
      }
      return null;
    },
  });

  if (withEncryption)
    return {
      ...overHeight,
      ...whitelistEncryptedDef("height", {
        whitelist,
        fieldRequired: required && withEncryption,
        validate: validateHeight,
      }),
    };

  return {
    ...overHeight,
  };
};

export const financingSectionDef = ({
  searchType,
  whitelist,
}: {
  searchType?: SearchType | null;
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => ({
  financing: {
    ...whitelistValueDef("financing", {
      whitelist,
    }),
    ...carelevelDef({ whitelist }),
    ...whitelistValueDef("public_care_insurance_status", {
      whitelist,
    }),
    ...whitelistValueDef("patient_has_health_insurance_state", {
      whitelist,
    }),
    ...whitelistValueDef("reimbursment_for_help_at_home", {
      whitelist,
    }),
    ...whitelistValueDef("reimbursment_secured", {
      whitelist,
    }),
    ...whitelistValueDef("preventative_care", {
      whitelist,
    }),
    ...whitelistValueDef("short_term_care", {
      whitelist,
    }),
    ...whitelistValueDef("relief_services", { whitelist }),
    ...whitelistValueDef("private_payment_bool", { whitelist }),
    ...whitelistValueDef("social_help_recipient_bool", {
      whitelist,
    }),
    ...whitelistValueDef("more_information", { whitelist }),
    ...algoliaReactSelectDef("insurance", {
      whitelist,
      out_name: "financing_insurance",
    }),
    ...whitelistEncryptedDef("insurance_number", {
      fieldRequired: searchType === SEARCH_TYPE_REHABILITATION,
      whitelist,
    }),
    ...whitelistValueDef("insurance_id", { whitelist }),
    ...whitelistValueDef("payout", {
      whitelist,
    }),
  },
});

const timeConvertOut = (value: Date | number) => {
  if (!value) return { hour: null, minute: null };
  const stringValue = format(value, "HH:mm");
  const [hour, minute] = stringValue.split(":");
  return {
    hour: Number(hour),
    minute: Number(minute),
  };
};

export const pickUpTimeDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) =>
  whitelistGroupDef("pick_up", {
    whitelist,
    ...valueDef("start", {
      fieldRequired: true,
      convertIn: formatPickUpTime,
      convertOut: timeConvertOut,
    }),
    ...valueDef("end", {
      fieldRequired: true,
      convertIn: formatPickUpTime,
      convertOut: timeConvertOut,
    }),
  });

export const liftAvailableWhitelist = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => ({
  ...whitelistValueDef("lift_available_state", { whitelist }),
  ...whitelistValueDef("lift_available_description", { whitelist }),
});

export const barrierFreeWhitelist = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => ({
  ...whitelistValueDef("barrier_free", { whitelist }),
  ...whitelistValueDef("barrier_free_description", { whitelist }),
});

export const unsafeDomesticSituationWhitelist = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) => ({
  ...whitelistValueDef("unsafe_current_domestic_situation_state", {
    whitelist,
  }),
  ...whitelistValueDef("unsafe_current_domestic_situation", { whitelist }),
});

export const transportMonitoringNeedsDef = ({
  whitelist,
}: {
  whitelist: PatientWhitelist[AssessmentStepTypeName] | undefined;
}) =>
  whitelistGroupDef("monitoring_needs", {
    whitelist,
    ...whitelistValueDef("ecg", {
      out_name: "monitoring_ecg",
      whitelist,
    }),
    ...whitelistValueDef("pulse_oximeter", {
      out_name: "monitoring_pulse",
      whitelist,
    }),
  });

export const accessAndDrainageModelDefinition = ({
  whitelist,
}: {
  whitelist: any;
}) =>
  whitelistActivableInputModelDefinition(
    "patient.care_needs.access_drainages",
    "access_drainages",
    {
      whitelist,
      ...whitelistValueDef("tracheal_cannula", { whitelist }),
      ...whitelistValueDef("gastric_tube", { whitelist }),
      ...whitelistValueDef("central_venous_catheter", { whitelist }),
      ...whitelistValueDef("peripheral_venous_catheter", { whitelist }),
      ...whitelistValueDef("urinary_catheter", { whitelist }),
      ...whitelistValueDef("suprapubic_catheter", { whitelist }),
      ...whitelistValueDef("permanent_catheter", { whitelist }),
      ...whitelistValueDef("drainage", { whitelist }),
      ...whitelistValueDef("intravenous_injection", { whitelist }),
      ...whitelistValueDef("other_access_drainages", {
        whitelist,
        validate(value, props) {
          if (typeof value === "string" && !value) {
            return required({
              errorString: props.translations.actions.missing,
            })(value, props);
          }

          return true;
        },
      }),
    },
  );

export const monitoringModelDefinition = ({ whitelist }: { whitelist: any }) =>
  whitelistActivableInputModelDefinition(
    "patient.care_needs.monitoring",
    "monitoring",
    {
      whitelist,
      ...whitelistValueDef("circulation", { whitelist }),
      ...whitelistValueDef("respiration", { whitelist }),
      ...whitelistValueDef("metabolism", { whitelist }),
    },
  );

export const medicationModelDefinition = ({ whitelist }: { whitelist: any }) =>
  whitelistActivableInputModelDefinition(
    "patient.care_needs.medication",
    "medication",
    {
      whitelist,
      ...whitelistValueDef("description", {
        out_name: "medication_description",
        whitelist,
      }),
      ...whitelistValueDef("blood_thinner", {
        whitelist,
      }),
    },
  );

export const woundsModelDefinition = ({ whitelist }: { whitelist: any }) =>
  whitelistActivableInputModelDefinition(
    "patient.care_needs.wound_care",
    "wound_care",
    {
      whitelist,
      ...whitelistValueDef("description", {
        out_name: "wound_care_description",
        whitelist,
      }),
    },
  );

export const breathingModelDefinition = ({ whitelist }: { whitelist: any }) =>
  whitelistActivableInputModelDefinition(
    "patient.care_needs.breathing",
    "breathing",
    {
      whitelist,
      ...whitelistValueDef("tracheostomy", { whitelist }),
      ...whitelistValueDef("specialised_intensive_nurse", { whitelist }),
      ...whitelistValueDef("oxygen_therapy", { whitelist }),
    },
  );
