import { useMatch } from "react-router-dom";
import useProductTour, { PRODUCT_TOURS } from "../..";

export const useParallelSearchTour = ({
  hasParallelSearchOption,
}: {
  hasParallelSearchOption: boolean;
}) => {
  const isPatientProfile = useMatch(
    `/app/clinic/dashboard/patient/:patientid/auction/:auctionid/profile`,
  );

  useProductTour({
    tour: PRODUCT_TOURS.parallel_search,
    skip: !isPatientProfile || !hasParallelSearchOption,
  });
};
