import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

export const scrollToTop = () => {
  const pageTop = window.pageYOffset || 0;
  const docTop = document.documentElement?.scrollTop || 0;
  const bodyTop = document.body?.scrollTop || 0;
  const topValue = Math.max(pageTop, docTop, bodyTop);

  if (topValue != 0) {
    const heightToTop = document.body?.scrollHeight;
    window.scrollBy(0, -heightToTop);
  }
};

export const useScrollToTop = () => {
  useLayoutEffect(() => {
    scrollToTop();
  }, []);
};

export const useScrollToTopOnPathChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);
};

export function useScrollToElement(
  initialBehavior: ScrollBehavior | "instant" = "smooth",
  options: { shouldScroll: boolean } = { shouldScroll: true },
) {
  const { shouldScroll } = options;
  const [scrolled, setScrolled] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToElement = useCallback(
    (behavior: ScrollBehavior | "instant" = "smooth") => {
      ref.current?.scrollIntoView({
        behavior: (behavior || initialBehavior) as ScrollBehavior,
        block: "end",
        inline: "end",
      });
      setScrolled(true);
    },
    [initialBehavior],
  );

  useEffect(() => {
    if (ref.current && shouldScroll) {
      scrollToElement();
    }
  }, [ref.current, shouldScroll, scrollToElement]);

  return { ref, scrolled, scrollToElement };
}
