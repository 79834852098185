import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import { useTranslations } from "translations";

export const AftercareTypes = () => {
  const translations = useTranslations();
  const aftercareOptions = [
    {
      name: "aftercare_mobile",
      label: translations.patientForms.transitionalCareForm.sectionC.mobileCare,
    },
    {
      name: "aftercare_shortterm_care",
      label:
        translations.patientForms.transitionalCareForm.sectionC.shorttermCare,
    },
    {
      name: "aftercare_medical_rehab",
      label:
        translations.patientForms.transitionalCareForm.sectionC.medicalRehab,
    },
  ];

  return (
    <>
      {aftercareOptions.map((option) => (
        <CheckboxInputField
          key={option.name}
          elementName={option.name}
          label={option.label}
        />
      ))}
    </>
  );
};
