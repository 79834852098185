import { useInterval } from "core/hooks";
import { useState } from "react";

// remount component every 'duration' milisseconds
export default function useRefreshComponent(duration: number | null) {
  const [refreshIndex, setRefreshIndex] = useState(0);
  useInterval(() => {
    setRefreshIndex(refreshIndex + 1);
  }, duration);

  return refreshIndex;
}
