import { ListItem, ListItemText, TextField } from "@mui/material";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { SelectOption } from "ds_legacy/components/Select/types";
import { dp, margin } from "ds_legacy/materials/metrics";
import { FONT_SIZE_13 } from "ds_legacy/materials/typography";
import { useState } from "react";
import { useTranslations } from "translations";

export default function SocialWorkerAutoComplete({
  currentValue,
  defaultValue,
  disabled,
  onChange,
  options,
}: Pick<
  AutocompleteProps<SelectOption, false, false, false>,
  "defaultValue" | "disabled" | "options"
> & {
  currentValue: number;
  onChange: (v: SelectOption) => void;
}) {
  const translations = useTranslations();
  const [selectedSocialWorker, setSelectedSocialWorker] =
    useState<SelectOption>(
      options.find(({ id }) => id === currentValue) as SelectOption,
    );

  return (
    <Autocomplete
      autoHighlight
      classes={makeStyles({
        input: {
          minWidth: `${dp(120)} !important`,
          fontSize: `${FONT_SIZE_13} !important`,
        },
        inputRoot: { paddingTop: "0 !important" },
      })()}
      componentsProps={{ paper: { sx: { width: "fit-content" } } }}
      value={selectedSocialWorker}
      data-testid="social-worker-autocomplete"
      defaultValue={defaultValue || undefined}
      disableClearable
      disabled={disabled}
      id="social-worker-autocomplete"
      onChange={(e, newValue) => {
        onChange(newValue);
        setSelectedSocialWorker(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      renderInput={(params) => (
        <TextField
          placeholder={translations.patient.menuAssigneePlaceholder}
          {...params}
          inputProps={{ ...params.inputProps }}
          hiddenLabel
          variant="standard"
        />
      )}
      renderOption={(props, { id, label }) => (
        <ListItem
          divider
          disablePadding
          data-testid={`option-${id}`}
          {...props}
        >
          <ListItemText primary={label} />
        </ListItem>
      )}
      sx={{
        marginLeft: margin(1),
        minWidth: "max-content",
        "& .MuiAutocomplete-popupIndicator": { padding: 0 },
      }}
    />
  );
}
