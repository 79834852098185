import { WHITE } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { margin, sizing } from "ds_legacy/materials/metrics";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import {
  DatePickerWithLabel,
  SMALL_INPUT_WIDTH,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

function HandoverToInsuranceDatePicker() {
  const { isTablet } = useMedia();
  const translations = useTranslations();

  return (
    <DatePickerWithLabel
      bold
      label={translations.patientForms.dateField.title}
      elementName="handover_to_insurance_date"
      textInputVariant="outlined"
      inputSx={{
        width: SMALL_INPUT_WIDTH,
        minWidth: isTablet ? sizing(17.5) : sizing(20),
        background: WHITE,
        margin: margin(0, 0, 1),
      }}
      disablePast
      wrapperSx={{ width: "100%" }}
    />
  );
}

export function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <HorizontalLayout
      style={{ justifyContent: "space-between", alignItems: "flex-end" }}
    >
      <HandoverToInsuranceDatePicker />
      {children}
    </HorizontalLayout>
  );
}
