import { VerticalLayout } from "ds_legacy/materials/layouts";
import { sizing } from "ds_legacy/materials/metrics";
import { GAP, Section } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";
import { PatientConfirmation } from "./PatientConfirmation";
import { SuitableProviders } from "./SuitableProviders";
import { TransitionalCareDescription } from "./TransitionalCareDescription";

export const SectionB = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.transitionalCareForm.sectionB.title}
    >
      <VerticalLayout gap={sizing(GAP)} overflow="visible">
        <PatientConfirmation />
        <TransitionalCareDescription />
        <SuitableProviders />
      </VerticalLayout>
    </Section>
  );
};
