import { GREY_600 } from "ds_legacy/materials/colors";
import { margin } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds_legacy/materials/typography";
import { useTranslations } from "translations";

export function FormSearchNotStarted() {
  const translations = useTranslations();

  return (
    <>
      <Subheading
        data-testid="not-started-banner"
        as="h3"
        margin={margin(0)}
        style={{
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
          color: GREY_600,
        }}
      >
        {translations.patientForms.transitionalCareForm.subtitleEmptyForm}
      </Subheading>
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        margin={margin(1, 0, 0)}
        maxWidth="100%"
      >
        {translations.patientForms.transitionalCareForm.descriptionEmptyForm}
      </Body>
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        margin={margin(2, 0, 0)}
        maxWidth="100%"
      >
        {
          translations.patientForms.transitionalCareForm
            .explanationTransitionalCare
        }
      </Body>
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        margin={margin(2, 0, 0)}
        maxWidth="100%"
      >
        {translations.patientForms.transitionalCareForm.numberOfProviders}
      </Body>
    </>
  );
}
