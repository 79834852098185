import { AUCTION_STATUS_NOT_STARTED } from "core/consts";
import { Auction } from "core/types";
import { useMatch } from "react-router-dom";
import useProductTour, { PRODUCT_TOURS } from "../..";

export const useTransitionalCareTour = ({
  auction,
  hasPreviousFormValue,
}: {
  auction: Auction;
  hasPreviousFormValue: boolean;
}) => {
  const isTransitionalCarePage = useMatch(
    `/app/clinic/dashboard/patient/:patientid/auction/:auctionid/transitional`,
  );

  useProductTour({
    tour: PRODUCT_TOURS.transitional_care,
    skip:
      !isTransitionalCarePage ||
      auction.status === AUCTION_STATUS_NOT_STARTED ||
      hasPreviousFormValue,
  });
};
