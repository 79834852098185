import { List, ListItem } from "@mui/material";
import { LinkButton } from "ds_legacy/components/LinkV2/LinkButton";
import { margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_20,
  FONT_SIZE_24,
  FONT_WEIGHT_BOLD,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { StyledFormWrapper } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

const NonSubscriberListItem = ({
  translationKey,
}: {
  translationKey: string;
}) => (
  <ListItem sx={{ display: "list-item", paddingLeft: 0, marginLeft: 4 }}>
    <Body as="p" margin={margin(-1, 0, 0, 1)} maxWidth="100%">
      {translationKey}
    </Body>
  </ListItem>
);

export function FormNonSubscribed() {
  const translations = useTranslations();

  return (
    <StyledFormWrapper>
      <Title
        as="h2"
        margin={margin(0, 0, 2)}
        style={{
          fontSize: FONT_SIZE_24,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.transitionalCareForm.title}
      </Title>
      <Subheading
        as="h3"
        margin={margin(0, 0, 1)}
        style={{
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {translations.patientForms.transitionalCareForm.notPurchased.title}
      </Subheading>
      <Body as="p" margin={margin(1, 0, 0)} maxWidth="100%">
        {
          translations.patientForms.transitionalCareForm.notPurchased
            .paragraphOne
        }
      </Body>
      <Body as="p" margin={margin(1, 0, 2)} maxWidth="100%">
        {
          translations.patientForms.transitionalCareForm.notPurchased
            .paragraphTwo
        }
      </Body>
      <Subheading
        as="h3"
        margin={margin(0, 0, 1)}
        style={{
          fontSize: FONT_SIZE_20,
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {
          translations.patientForms.transitionalCareForm.notPurchased
            .subtitleAdvantages
        }
      </Subheading>
      <List
        sx={{
          listStyleType: "disc",
          padding: padding(0, 0, 0, 0),
          marginTop: 0,
        }}
      >
        <NonSubscriberListItem
          translationKey={
            translations.patientForms.transitionalCareForm.notPurchased
              .advantageOne
          }
        />
        <NonSubscriberListItem
          translationKey={
            translations.patientForms.transitionalCareForm.notPurchased
              .advantageTwo
          }
        />
        <NonSubscriberListItem
          translationKey={
            translations.patientForms.transitionalCareForm.notPurchased
              .advantageThree
          }
        />
        <NonSubscriberListItem
          translationKey={
            translations.patientForms.transitionalCareForm.notPurchased
              .advantageFour
          }
        />
        <NonSubscriberListItem
          translationKey={
            translations.patientForms.transitionalCareForm.notPurchased
              .advantageFive
          }
        />
      </List>

      <LinkButton
        href="mailto:sales@recaresolutions.com"
        target="_blank"
        sx={{ margin: margin(2, 0, 0, 0) }}
      >
        {translations.patientForms.transitionalCareForm.notPurchased.cta}
      </LinkButton>
    </StyledFormWrapper>
  );
}
