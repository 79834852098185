import { GET_TRANSITIONAL_CARE } from "core/apollo/graphql";
import {
  QUERY_PROGRESS_SUCCEED,
  SEARCH_ACTION_DELETE_TRANSITIONAL_CARE_FORM,
} from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Auction, QueryProgress, TransitionalCareFormData } from "core/types";
import ConfirmationDialog from "ds_legacy/components/ConfirmationDialog";
import { useConfirmationDialog } from "ds_legacy/components/ConfirmationDialog/useConfirmationDialog";
import RSButton from "ds_legacy/components/RSButton";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { FormSectionsWrapper } from "dsl/ecosystems/PatientForms/components";
import { useImportFormData } from "dsl/ecosystems/PatientForms/utils";
import useSearchAction from "dsl/hooks/useSearchAction";
import {
  PRODUCT_TOURS,
  TourAttributeWrapper,
} from "dsl/molecules/useProductTour";
import { useTransitionalCareTour } from "dsl/molecules/useProductTour/tours/TransitionalCareTour";
import { FormWatcher } from "react-forms-state";
import { useTranslations } from "translations";
import { getFormGetters } from "../Import";
import { SectionA } from "./SectionA";
import { SectionAHeader } from "./SectionA/header";
import { SectionB } from "./SectionB";
import { SectionC } from "./SectionC";
import { SectionD } from "./SectionD";
import { SectionE } from "./SectionE";
import { SectionF } from "./SectionF";
import { SectionHeader } from "./SectionHeader";
import { SectionOne } from "./SectionOne";
import { SectionTwo } from "./SectionTwo";

const useDeleteConfirmation = ({
  auction,
  formOnChange,
}: {
  auction: Auction;
  formOnChange: (value: any, statePath: string, validation: any) => void;
}) => {
  const translations = useTranslations();
  const [deleteForm, deleteFormProgress] = useSearchAction({
    actionType: SEARCH_ACTION_DELETE_TRANSITIONAL_CARE_FORM,
  });
  const [confirmProps, confirm] = useConfirmationDialog();

  const onDelete = async (formValue: TransitionalCareFormData) => {
    try {
      const confirmed = await confirm({
        title: translations.patientForms.fileDeletion.title,
        content: translations.patientForms.fileDeletion.text,
        submitText: translations.patientForms.fileDeletion.confirmButton,
        cancelText: translations.patientForms.fileDeletion.cancelButton,
        confirmButtonProps: {
          color: "error",
          variant: "contained",
        },
      });

      if (!confirmed) return null;

      await deleteForm({
        auction,
        onCompleted: (_, client) => {
          client.writeQuery({
            query: GET_TRANSITIONAL_CARE,
            variables: {
              auctionId: auction.id,
              patientId: auction.patient.id,
            },
            data: {
              auction: {
                id: auction.id,
                transitional_care: null,
                seald_encryption_context: auction.seald_encryption_context,
                session_key_context: auction.session_key_context,
              },
            },
          });
        },
      });
      Object.keys(formValue).forEach((key) => formOnChange(null, key, null));
    } catch (err) {
      console.error(
        `error deleting transitional care form: ${getErrorMessage(err)}`,
      );
    }
  };

  return { confirmProps, onDelete, deleteFormProgress };
};

const FormButtons = ({
  auction,
  formOnChange,
  hasPreviousFormValue,
  onSubmitProgress,
  submit,
  withDelete,
}: {
  auction: Auction;
  formOnChange: (value: any, statePath: string, validation: any) => void;
  hasPreviousFormValue: boolean;
  onSubmitProgress: QueryProgress;
  submit: () => void;
  withDelete?: boolean;
}) => {
  useTransitionalCareTour({ auction, hasPreviousFormValue });

  const translations = useTranslations();
  const getOntology = useGetOntology();

  const { importFormData, importProgress } = useImportFormData({
    formOnChange,
    auction,
    formGetters: getFormGetters(getOntology, translations),
  });
  const { confirmProps, deleteFormProgress, onDelete } = useDeleteConfirmation({
    auction,
    formOnChange,
  });

  const isFormFilled =
    hasPreviousFormValue || importProgress === QUERY_PROGRESS_SUCCEED;

  return (
    <>
      <HorizontalLayout
        margin={margin(0, 0, 1)}
        justify={withDelete ? "space-between" : "flex-end"}
      >
        {withDelete && (
          <FormWatcher watchPath="">
            {({ watchedValue }) => {
              return (
                <RSButton
                  color="error"
                  id="delete-form"
                  type="submit"
                  loading={deleteFormProgress}
                  variant="outlined"
                  onClick={() =>
                    onDelete(watchedValue as TransitionalCareFormData)
                  }
                >
                  {translations.patientForms.transitionalCareForm.deleteForm}
                </RSButton>
              );
            }}
          </FormWatcher>
        )}
        <HorizontalLayout justify="flex-end">
          <TourAttributeWrapper
            tourKey={PRODUCT_TOURS.transitional_care.key}
            stepKey={PRODUCT_TOURS.transitional_care.steps.SAVE_CREATE_PDF.key}
          >
            <RSButton
              color="primary"
              id={hasPreviousFormValue ? "overwrite-pdf" : "generate-pdf"}
              type="submit"
              loading={onSubmitProgress}
              variant="outlined"
              onClick={submit}
            >
              {hasPreviousFormValue
                ? translations.patientForms.buttonSaveOverwrite
                : translations.patientForms.buttonSave}
            </RSButton>
          </TourAttributeWrapper>
          <TourAttributeWrapper
            tourKey={PRODUCT_TOURS.transitional_care.key}
            stepKey={PRODUCT_TOURS.transitional_care.steps.IMPORT_DATA.key}
          >
            <RSButton
              color="primary"
              id="import-auction"
              type="submit"
              loading={importProgress}
              variant={isFormFilled ? "outlined" : "contained"}
              onClick={importFormData}
            >
              {isFormFilled
                ? translations.patientForms.buttonRefreshData
                : translations.patientForms.buttonImport}
            </RSButton>
          </TourAttributeWrapper>
        </HorizontalLayout>
      </HorizontalLayout>
      <ConfirmationDialog {...confirmProps} />
    </>
  );
};

export function TransitionalCareFormPresenter({
  auction,
  formOnChange,
  hasPreviousFormValue,
  onSubmitProgress,
  submit,
}: {
  auction: Auction;
  formOnChange: (value: any, statePath: string, validation: any) => void;
  hasPreviousFormValue: boolean;
  onSubmitProgress: QueryProgress;
  submit: () => void;
}) {
  const ButtonSection = ({ withDelete }: { withDelete?: boolean }) => (
    <FormButtons
      hasPreviousFormValue={hasPreviousFormValue}
      auction={auction}
      onSubmitProgress={onSubmitProgress}
      submit={submit}
      formOnChange={formOnChange}
      withDelete={withDelete}
    />
  );

  return (
    <VerticalLayout width="100%" margin={margin(3, 0)}>
      <SectionHeader>
        <ButtonSection />
      </SectionHeader>
      <FormSectionsWrapper>
        <SectionOne />
        <SectionTwo />
      </FormSectionsWrapper>
      <SectionAHeader />
      <FormSectionsWrapper noBottomMargin>
        <SectionA />
        <SectionB />
        <SectionC />
        <SectionD />
        <SectionE auctionId={auction.id} />
        <SectionF />
      </FormSectionsWrapper>
      <ButtonSection withDelete />
    </VerticalLayout>
  );
}
