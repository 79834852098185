import { isLoading } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import { APP_CLINIC, REDIRECT_PARAM } from "core/consts";
import { activateEnvSwitch, isNewTheme } from "core/model/utils/featureFlags";
import { getQueryVariable } from "core/model/utils/urls";
import { useManualSetLegalDocuments } from "dsl/atoms/LegalDocuments";
import { LoginPageView } from "dsl/ecosystems/LoginPage";
import { useHandleLogin } from "dsl/ecosystems/LoginPage/useHandleLogin";
import { useLoginPage } from "dsl/ecosystems/LoginPage/useLoginPage";
import { LoginPageV2 } from "dsl/ecosystems/LoginPageV2";
import {
  loginPageModelDefinition,
  validateChallenge,
  validateLogin,
  validateSingleSignOn,
} from "dsl/ecosystems/LoginPageV2/formConfig";
import {
  useCareseekerHandlerUrls,
  useCareseekerNavigationHandlers,
} from "dsl/hooks/useNavigationHandlers";
import { SimpleFormRenderProps } from "react-forms-state/src";
import { Navigate, useLocation } from "react-router-dom";
import { useIsLoggedOnClinicApp } from "reduxentities/selectors";
import { useSingleSignOn } from "../LoginPage/useSingleSingOn";
import { LoginLoadingPage } from "../LoginPageV2/LoginLoadingPage";

export function CareseekerAuthPage() {
  const location = useLocation();
  const { env } = useEnvContext();
  const logged = useIsLoggedOnClinicApp();
  const {
    challenge,
    email,
    error,
    loginProgress,
    module,
    oauthToken,
    onLogin,
    password,
    setChallenge,
    setEmail,
    setModule,
    setPassword,
    status,
  } = useLoginPage();
  const {
    challengeTimestamp,
    handleLogin,
    isLogin,
    loginErrors,
    loginView,
    progressLogin,
    resetError,
    setLoginView,
    setProgressLogin,
  } = useHandleLogin();
  const {
    checkSsoEligibility,
    handleSsoLogin,
    isSsoEligible,
    isSsoRedirect,
    progressSso,
    progressValidateEmail,
    ssoUrl,
  } = useSingleSignOn({ handleLogin });
  const { goToForgotPassword: goToForgotPasswordHandler } =
    useCareseekerNavigationHandlers();
  const {
    goToContactUs,
    goToForgotPassword,
    goToProductionEnv,
    goToProviderApp,
  } = useCareseekerHandlerUrls();

  // Setting the legal documents to default on the login screen, otherwise
  // it tries to fetch with the id (logout/onboarding), although logged out
  useManualSetLegalDocuments();

  return logged === "ok" ? (
    <Navigate
      to={getQueryVariable(location.search, REDIRECT_PARAM) || "/app"}
    />
  ) : (
    <>
      {isNewTheme() ? (
        <main>
          {isSsoRedirect && isLoading(progressSso) ? (
            <LoginLoadingPage app={APP_CLINIC} env={env} />
          ) : (
            <SimpleFormRenderProps
              asHtmlForm
              onSubmit={(props) =>
                ssoUrl ? handleSsoLogin() : handleLogin(props, setProgressLogin)
              }
              modelDefinition={loginPageModelDefinition}
              validate={
                isLogin
                  ? ssoUrl
                    ? validateSingleSignOn
                    : validateLogin
                  : validateChallenge
              }
            >
              {({ onChange, submit }) => (
                <LoginPageV2
                  app={APP_CLINIC}
                  challengeTimestamp={challengeTimestamp}
                  contactUsUrl={goToContactUs()}
                  env={env}
                  goToForgotPassword={goToForgotPassword()}
                  goToProductionUrl={goToProductionEnv()}
                  handleLogin={(props) => handleLogin(props, setProgressLogin)}
                  isEmailInputLoading={isLoading(progressValidateEmail)}
                  isLoading={isLoading([progressLogin, progressSso])}
                  isSingleSignOn={isSsoEligible}
                  loginErrors={loginErrors}
                  loginView={loginView}
                  onBackToLogin={() => {
                    onChange(null, "challenge", true);
                    onChange(null, "email", true);
                    onChange(null, "password", true);
                    resetError();
                    setLoginView("login");
                  }}
                  onChangeEmail={(email) => checkSsoEligibility({ email })}
                  onChangeFormValues={onChange}
                  onSubmit={submit}
                  otherAppUrl={goToProviderApp()}
                  resetError={resetError}
                  withDevTools={activateEnvSwitch}
                />
              )}
            </SimpleFormRenderProps>
          )}
        </main>
      ) : (
        <LoginPageView
          challenge={challenge}
          email={email}
          error={error ?? null}
          module={module}
          onLogin={onLogin}
          onLoginQueryProgress={loginProgress}
          password={password}
          passwordRequired={!oauthToken}
          setChallenge={setChallenge}
          setEmail={setEmail}
          setModule={setModule}
          setPassword={setPassword}
          status={status}
          onPasswordResetPage={() => goToForgotPasswordHandler()}
        />
      )}
    </>
  );
}
