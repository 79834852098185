import {
  DatePickerWithLabel,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { FormWatcher } from "react-forms-state/src";
import { useTranslations } from "translations";

export const PriorTreatmentSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.hospitalStay
          .titlePriorStay
      }
    >
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.hospitalStay
              .intakeDate
          }
          elementName="prior_treatment_start_date"
          disableFuture
        />
        <FormWatcher watchPath="prior_treatment_start_date">
          {({ watchedValue }) => {
            return (
              <DatePickerWithLabel
                bold
                label={
                  translations.patientForms.transitionalCareForm.hospitalStay
                    .releaseDate
                }
                elementName="prior_treatment_end_date"
                blockBefore={watchedValue}
              />
            );
          }}
        </FormWatcher>
        <TextInputWithLabel
          bold
          label={translations.patientForms.transitionalCareForm.hospitalStay.id}
          elementName="prior_treatment_hospital_id"
          large
        />
      </SectionRow>
    </Section>
  );
};
