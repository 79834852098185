import {
  DatePickerWithLabel,
  Section,
  SectionRow,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionA = () => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.transitionalCareForm.sectionA.title}
    >
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.sectionA.dateLabel
          }
          elementName="transitional_care_start_search_date"
          wrapperSx={{ width: "100%" }}
          inputSx={{
            width: SMALL_INPUT_WIDTH,
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
          disableFuture
        />
      </SectionRow>
    </Section>
  );
};
