import { useSafeState } from "core/hooks";
import { isAuctionSuccess } from "core/model/auctions";
import { Auction } from "core/types";
import { Circle } from "ds_legacy/components/Circles";
import { ERROR_COLOR, PRIMARY_DARK_COLOR, WHITE } from "ds_legacy/materials/colors";
import { margin } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
} from "react";
import { Outlet } from "react-router-dom";
import Translations from "translations/types";

export type CandidateContext = {
  candidates: number | null | undefined;
  patientId?: number;
  services?: Array<number>;
};

type SearchCandidatesContextType = {
  candidateContext: CandidateContext;
  hasCandidates?: boolean;
  setCandidates: Dispatch<SetStateAction<CandidateContext>>;
};
const defaultSearchCandidatesContext = {
  candidateContext: {
    candidates: null,
    services: undefined,
    patientId: undefined,
  },
  hasCandidates: undefined,
  setCandidates: () => {},
};

const SearchCandidatesContext = createContext<SearchCandidatesContextType>(
  defaultSearchCandidatesContext,
);

export function useCandidates(): {
  candidateContext: CandidateContext;
  hasCandidates?: boolean;
  setCandidates: Dispatch<SetStateAction<CandidateContext>>;
} {
  return useContext(SearchCandidatesContext);
}

export function SearchCandidatesProvider({
  children,
  defaultCandidates,
}: {
  children?: ReactNode;
  defaultCandidates?: number | null;
}) {
  const [candidateContext, setCandidates] = useSafeState({
    candidates: defaultCandidates,
  });

  return (
    <SearchCandidatesContext.Provider
      value={{
        candidateContext,
        setCandidates,
        hasCandidates:
          !candidateContext || candidateContext.candidates === undefined
            ? undefined
            : candidateContext.candidates !== 0,
      }}
    >
      {children ? children : <Outlet />}
    </SearchCandidatesContext.Provider>
  );
}

export function shouldDisplayCandidates(auction: Auction | undefined): boolean {
  return (auction && !isAuctionSuccess(auction.status)) || false;
}

export function CandidatesCircle({
  candidateContext,
  patientId,
  translations,
}: {
  candidateContext: CandidateContext;
  patientId: number | null;
  translations: Translations;
}) {
  if (!candidateContext || candidateContext.candidates === undefined) {
    return null;
  }
  const differentPatient = candidateContext?.patientId != patientId;
  const noCandidates = candidateContext && !candidateContext.candidates;
  const tooltipLabel = noCandidates
    ? translations.patient.noCandidates
    : translations.patient.candidates;

  return (
    <Circle
      color={
        differentPatient || noCandidates ? ERROR_COLOR : PRIMARY_DARK_COLOR
      }
      diameter={40}
      elevation={4}
      tooltipLabel={tooltipLabel}
      tooltipTextAlign="center"
    >
      <Body color={WHITE} margin={margin(0)}>
        {candidateContext?.candidates || 0}
      </Body>
    </Circle>
  );
}
